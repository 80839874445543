@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.features-history-item {
  background-color: transparent;
  float: left;
  height: 100%;
  padding: 0;
  position: relative;
  width: 100%;

  &--info,
  &--image {
    display: inline-block;
    float: left;
    position: relative;
    width: 100%;
    vertical-align: top;
  }

  &--info {
    padding: 25px 30px 30px;

    .description,
    .link-item {
      margin: 0;
      position: relative;
      width: 100%;
      vertical-align: top;
    }

    .description {
      @include font($font-default, 16px, 400, $neutra);
      background-color: transparent;
      line-height: 1.5em;
      
      p {
        background-color: transparent;
        margin: 0 0 10px;
        line-height: 1.4em;
      }
    }

    .link-item {
      @include font($font-default, 12px, 800, $neutra);
      margin: 20px 0 30px;
      text-decoration: none;
      text-transform: uppercase;
      width: auto;
    }
  }

  &--image {
    pointer-events: none;
    padding: 0;

    .date {
      @include font($font-default, 20px, 600, $neutra);
      background-color: $secondary-color;
      bottom: 0;
      line-height: 1em;
      left: 0;
      margin: 0;
      padding: 10px 20px;
      position: absolute;
      text-transform: uppercase;
    }

    img {
      float: left;
      height: auto;
      object-fit: cover;
      position: relative;
      width: 100%;
      will-change: transform;
    }
  }

  &[data-type="1"] {
    background-color: $secondary-color;
  }

  &[data-type="2"] {
    background-color: $secondary-dark-200;
  }

  &[data-type="3"] {
    background-color: $secondary-dark;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .features-history-item {
    width: 400px;
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .features-history-item {
    width: 310px;
  }
}