@import '../../../styles/settings/colors';

@import '../../../styles/components/animations';

.timeline-list {
  float: left;
  height: 100%;
  pointer-events: auto;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  vertical-align: top;
  z-index: 2;

  &:before {
    background-color: $primary-color;
    content: '';
    left: 0;
    height: 100%;
    position: absolute;
    top: 0;
    transition: all 600ms $base-ease;
    width: 100%;
    vertical-align: top;
  }

  &[data-type="tipo_2"] {
    &:before {
      background-color: $secondary-color;
    }
  }

  &[data-type="tipo_3"] {
    &:before {
      background-color: $neutra-05;
    }
  }

  &[data-type="tipo_4"] {
    &:before {
      background-color: $secondary-dark;
    }
  }
}
