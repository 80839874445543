/* --- ANIMATIONS --- */

$base-duration: 600ms;
$base-ease: cubic-bezier(0.250, 0.460, 0.450, 0.940);

/* --- ANIMATION --- */
@keyframes fade-in-fwd {
  0% {
    opacity: 0;
    top: 40%;
  }
  100% {
    opacity: 1;
    top: 50%;
  }
}

@keyframes fade-in-fwd-mb {
  0% {
    bottom: 50%;
    opacity: 0;
  }
  100% {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes mover {
  0% { 
    transform: translate(-50%, 0); 
  }
  50% { 
    transform: translate(-50%, 20px); 
  }
  75% { 
    transform: translate(-50%, 0); 
  }
  100% { 
    transform: translate(-50%, 0); 
  }
}

@keyframes slide-top {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

 @keyframes scale-up-ver-top {
  0% {
    opacity: 0;
    transform: scaleY(0.4);
    transform-origin: 100% 0%;
  }
  70% {
    opacity: 1;
    transform: scaleY(1);
    transform-origin: 100% 0%;
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
    transform-origin: 100% 0%;
  }
}

@keyframes fade-in-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes scale-up-center {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  70% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-in-right-opacity {
  0% {
    transform: translate(50px, -50%);
    opacity: 0;
  }
  100% {
    transform: translate(0, -50%);
    opacity: 1;
  }
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-out-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes slide-out-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
