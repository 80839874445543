@import '../../../styles/components/animations';
@import '../../../styles/settings/colors';

.webdoor-controls {
  align-content: flex-end;
  align-items: center;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.58) 0%, rgba(0, 0, 0, 0) 100%);
  display: inline-flex;
  flex-flow: column wrap;
  justify-content: center;
  justify-items: center;
  left: 0;
  margin: 0;
  padding: 0 35px 0 40px;
  height: 100%;
  text-align: right;
  position: absolute;
  transition: all 400ms $base-ease;
  top: 0;
  width: 168px;
  z-index: 4;

  &--list {
    margin: 0;
    list-style: none;
    padding: 0;
    position: relative;
    text-align: right;
    width: 18px;

    &:before,
    &:after {
      background-color: $neutra;
      border-radius: 100%;
      content: '';
      height: 6px;
      left: 50%;
      position: absolute;
      transform: translate(-50%, 0);
      width: 6px;
    }

    &:before {
      top: -6px;
    }

    &:after {
      bottom: -6px;
    }
  }

  &--item {
    float: left;
    margin: 20px 0;
    position: relative;
    width: 100%;
    vertical-align: top;

    .btn-circle {
      pointer-events: auto;
      
      &[data-active="true"] {
        pointer-events: none;
      }
    }

    &:before,
    &:after {
      background-color: $neutra;
      content: '';
      height: 20px;
      left: 50%;
      position: absolute;
      transform: translate(-50%, 0);
      width: 0.1em;
    }

    &:before {
      top: 100%;
    }

    &:after {
      bottom: 100%;
    }
  }

  &:before {
    background-color: rgba($neutra-04, 0.8);
    content: '';
    height: 100%;
    left: 0;
    opacity: 0.8;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .webdoor-controls {
    bottom: 0;
    align-items: center;
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: 80px;
    padding: 0 20px;
    top: initial;
    text-align: center;
    width: 100%;

    &--item {
      margin: 0 20px;
      width: auto;

      &:after,
      &:before {
        height: 2px;
        top: 50%;
        transform: translate(0, -50%);
        width: 20px;
      }

      &:after {
        left: 100%;
      }

      &:before {
        left: initial;
        right: 100%;
      }
    }

    &--list {
      align-items: center;
      display: inline-flex;
      justify-content: center;
      flex-flow: row nowrap;
      position: relative;
      width: auto;

      &:after,
      &:before {
        top: 50%;
        transform: translate(0, -50%);
      }

      &:before {
        left: -5px;
      }

      &:after {
        left: 100%;
      }
    }
  }
}
