@import '../../styles/settings/grid';

.client-slider {
  float: left;
  margin: 20px 0;
  padding: 0 20px;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .client-slider {
    padding: 0 40px;
  }
}