.ships-thumb {
  float: left;
  position: relative;
  width: 100%;
  vertical-align: top;

  img {
    float: left;
    height: auto;
    width: 100%;
    vertical-align: top;
  }
}