@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.select {
  border: 1px solid $neutra-03;
  float: left;
  min-width: 230px;
  position: relative;
  text-align: left;
  vertical-align: top;
  width: 100%;
  z-index: 9;

  &-control {
    float: left;
    margin: 10px 0;
    padding: 0;
    width: 100%;
    vertical-align: top;

    &[data-error="true"] {
      .select {
        border: 2px solid $secondary-dark;

        &-title {
          &--content {
            .icon,
            .text {
              color: $secondary-dark;
            }
          }
        }
      }
    }

    &[data-active="true"] {
      .select {
        border: 2px solid $primary-color;
      }
    }
    &[data-error="true"] .error {
      height: auto;
      margin: 0;
      padding: 5px 15px;

      font-size: 14px;
      font-size: 0.875rem;
      color: #C14E2A;
      font-family: "TheSans", sans-serif;
      font-weight: 600;
      float: left;
      overflow: hidden;
      transition: all 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
      width: 100%;
      vertical-align: top;
    }
  }

  &--list {
    background-color: $neutra;
    border-radius: 0;
    border: 1px solid $neutra-03;
    display: none;
    left: -1px;
    max-height: 300px;
    margin: 0;
    overflow: hidden;
    overflow-y: auto;
    list-style: none;
    padding: 0;
    position: absolute;
    text-align: left;
    top: 100%;
    width: calc(100% + 2px);
    vertical-align: top;
    z-index: 1;

    &--item {
      float: left;
      position: relative;
      width: 100%;
      vertical-align: top;

      &:last-child {
        .select-item {
          border-bottom: none;
        }
      }
    }
  }

  &[data-active="true"] {
    z-index: 99999;

    .select {
      &--list {
        display: inline-block;
      }

      &-title {
        border-radius: 4px 4px 0 0;
      }
    }
  }
}
