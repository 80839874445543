@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/components/animations';
@import '../../../styles/components/forms/buttons';

@import '../../../styles/tools/mixins';

.timeline-controls {
  left: 0;
  max-width: 250px;
  padding: 25px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
  z-index: 999;

  &--item {
    @extend %btn-clear;
    float: left;
    padding: 10px 0;
    position: relative;
    width: 100%;
    vertical-align: top;

    .label {
      @include font($font-default, 16px, 400, $neutra);
      float: left;
      opacity: 1;
      padding: 0 0 0 30px;
      position: relative;
      transition: all 700ms $base-ease;
      user-select: none;
      white-space: nowrap;
    }

    &:before {
      background-color: $neutra;
      content: '';
      height: 4px;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      transition: all 700ms $base-ease;
      width: 15px;
    }

    &[data-type="home"] {
      margin: 0;
      padding: 10px 0;

      .label {
        opacity: 1;
        transition-delay: 100ms;
      }

      &[data-active="true"] {
        margin: 0 0 20px;
        padding: 10px 0;
        
        .label {
          display: none;
          opacity: 0;
        }
      }

      &:before {
        transition-duration: 200ms;
      }
    }

    &[data-active="true"] {
      pointer-events: none;

      .label {
        @include rem('font-size', 50px);
        padding-left: 100px;
      }

      &[data-type="tipo_1"] {
        &:before {
          background-color: $secondary-color;
        }
      }

      &[data-type="tipo_2"] {
        .label {
          color: $primary-light;
        }

        &:before {
          background-color: $primary-light;
        }
      }

      &[data-type="tipo_3"] {
        .label {
          color: $secondary-dark;
        }

        &:before {
          background-color: $secondary-dark;
        }
      }

      &[data-type="tipo_4"] {
        .label {
          color: $neutra;
        }

        &:before {
          background-color: $primary-color;
        }
      }

      &:before {
        background-color: $secondary-color;
        height: 8px;
        width: 85px;
      }
    }

    &:disabled {
      opacity: 1;
    }
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .timeline-controls {
    &--item {
      &[data-active="true"] {
        .label {
          padding-left: 0;
        }

        &:before {
          width: 0;
        }
      }
    }
  }
}