/* --- SELECT ITEM --- */
@import '../../../../../styles/settings/colors';
@import '../../../../../styles/settings/fonts';

@import '../../../../../styles/components/animations';

@import '../../../../../styles/tools/mixins';

.select-min-item {
  border: none;
  border-bottom: 1px solid rgba($neutra-03, 0.3);
  float: left;
  line-height: 1em;
  padding: 8px 0;
  position: relative;
  text-align: left;
  width: 100%;
  user-select: none;
  vertical-align: top;

  .text {
    @include font($font-default, 14px, 400, rgba($primary-color, 0.4));
    cursor: pointer;
    float: left;
    line-height: 1em;
    margin: 0;
    position: relative;
    text-align: left;
    transition: all 400ms $base-ease;
    width: 100%;
    white-space: nowrap;
  }

  &[data-active="true"] {
    .text {
      color: $primary-color;
    }
  }

  &:hover {
    .text {
      color: $primary-color;
    }
  }
}
