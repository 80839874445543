@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.search-item {
  float: left;
  position: relative;
  width: 100%;
  vertical-align: top;

  .breadcrumb,
  .description,
  .title {
    float: left;
    line-height: 1.4em;
    position: relative;
    text-decoration: none;
    width: 100%;
    vertical-align: top;
  }

  .breadcrumb {
    margin: 0 0 20px 0;
    padding: 0 0 20px;

    &--item {
      @include font($font-default, 12px, 300, $primary-light);
      float: left;
      margin: 0 15px 0 0;
      position: relative;
      text-decoration: none;

      .icon,
      .text {
        float: left;
        margin: 0;
        position: relative;
      }

      .icon {
        @include rem('font-size', 7px);
        position: absolute;
        right: -10px;
        top: calc(50% - 1px);
        transform: translate(0, -50%);
      }

      &:last-child {
        .icon {
          display: none;
        }
      }
    }
  }

  .description {
    @include font($font-default, 16px, 300, $neutra-06);
    padding-top: 20px;

    &:before {
      background-color: $secondary-color;
      content: '';
      height: 4px;
      position: absolute;
      top: 0;
      width: 40px;
    }
  }

  .title {
    @include font($font-default, 18px, 400, $primary-color);
    margin: 0 0 5px;
    text-transform: uppercase;
  }
}