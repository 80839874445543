@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.form-contact {
  float: left;
  margin: 0 auto 60px;
  padding: 0;
  position: relative;
  width: 100%;
  text-align: center;
  vertical-align: top;

  &--form {
    float: none;
    padding: 20px;
    margin: 0 auto;
    max-width: 450px;
    text-align: left;
    width: 100%;

    &--footer,
    &--text-info {
      float: left;
      position: relative;
      width: 100%;
      vertical-align: top;
    }

    &--footer {
      text-align: right;
      padding: 10px 0 0;

      .btn-border {
        background-color: white;
        transition: all .3s ease-in-out;

        &[type="submit"] {
          border-color: $secondary-color;
          color: $secondary-color;
        }

        &:hover {
          background-color: $secondary-color;
          color: white;
          cursor: pointer;
        }
      }
    }

    &--text-info {
      @include font($font-default, 14px, 600, $primary-dark);
      line-height: 1.4em;
      margin: 5px 0 10px;
    }

    .input-control {
      float: left;
      position: relative;
      width: 100%;
      vertical-align: top;
    }

    .upload-info {
      @include font($font-default, 14px, 600, $primary-dark);
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 32px;
    }
  }

  &--message {
    &--footer {
      float: left;
      margin: 20px 0 0;
      position: relative;
      text-align: center;
      width: 100%;
      vertical-align: top;

      .btn-border {
        margin: 0 15px;

        &.back {
          border-color: $secondary-color;
          color: $secondary-color;
        }
      }
    }
  }

  &--title {
    float: left;
    padding: 0 0 30px;
    position: relative;
    width: 100%;
    vertical-align: top;

    .input-control {
      float: right;
      margin: 0;
      top: -5px;
      width: auto;
    }

    .title {
      @include font($font-default, 18px, 400, $black-900);
      float: left;
      margin: 0;
      text-transform: uppercase;
      width: auto;
    }
  }
}