@import '../../styles/settings/grid';

.ships {
  align-items: center;
  display: inline-flex;
  float: left;
  flex-flow: row wrap;
  position: relative;
  width: 100%;
  vertical-align: top;

  &--container {
    float: none;
    margin: 30px auto;
    max-width: $page-width;
    padding: 0 20px;
    width: 100%;
    vertical-align: top;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .ships {
    &--container {
      padding: 0;
    }
  }
}