
@import '../../../styles/settings/grid';

.blog-container {
  float: none;
  max-width: $page-width;
  padding: 15px 20px;
  position: relative;
  text-align: left;
  width: 100%;
  vertical-align: top;
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .blog-container {
    padding: 0;
  }
}