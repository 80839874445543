@import '../../../../../styles/settings/colors';
@import '../../../../../styles/settings/fonts';

@import '../../../../../styles/tools/mixins';

.ships-card-modal-prop {
  border: none;
  border-top: 1px solid rgba($black-900, 0.1);
  float: left;
  list-style: none;
  margin: 0;
  padding: 10px 0;
  position: relative;
  width: 100%;
  vertical-align: top;

  &--item {
    float: left;
    margin: 10px 0;
    position: relative;
    width: 100%;
    vertical-align: top;

    .label,
    .value {
      margin: 0;
      position: relative;
      vertical-align: top;
    }

    .label {
      padding: 0 0 0 35px;
      .icon,
      .text {
        float: left;
      }

      .icon {
        @include rem('font-size', 24px);
        color: $neutra-04;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
      }

      .text {
        @include font($font-default, 16px, 400, $black-900);
        margin: 0;
        width: auto;
      }
    }

    .value {
      @include font($font-default, 20px, 600, $primary-color);
      float: right;
    }
  }
}