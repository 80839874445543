@import '../../../styles/settings/fonts';
@import '../../../styles/settings/grid';

@import '../../../styles/components/icons';
@import '../../../styles/components/forms/buttons';

@import '../../../styles/tools/mixins';

.client-slider-container {
  float: left;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  &--slider {
    float: none;
    margin: 0 20px;
    padding: 0;
    max-width: calc(#{$page-width} - 40px);
    position: relative;
    width: calc(100% - 40px);
    vertical-align: top;

    .slick {
      &-list,
      &-track { 
        touch-action: pan-y;
      }

      &-list {
        float: left;
        padding: 0;
        overflow: hidden;
        position: relative;
        width: 100%;
      }

      &-slide {
        align-items: flex-start;
        display: inline-flex;
        justify-content: center;

        > div {
          align-items: flex-start;
          display: inline-flex;
          justify-content: center;
          float: left;
          position: relative;
          width: 100%;
        }
      }

      &-slider {
        float: left;
        position: relative;
        width: 100%;
      }

      &-track {
        position: relative;
        margin: 0 auto;
        display: flex;
        align-items: center;
      }
    }
  }
}