@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.block-print-item {
  padding: 20px;
  position: relative;
  width: 100%;
  vertical-align: top;

  &--name,
  &--list {
    float: left;
    position: relative;
    width: 100%;
    vertical-align: top;
  }

  &--name {
    @include font($font-default, 20px, 400, $primary-color);
    margin: 0 0 15px;
  }

  &--list {
    margin: 0;
    list-style: none;
    padding: 0;

    &--item {
      float: left;
      margin: 5px 0;
      position: relative;
      width: 100%;
      vertical-align: top;

      .label {
        @include font($font-default, 16px, 400, $primary-dark);
        float: left;
        margin: 0;
        position: relative;

        strong {
          margin-left: 2px;
        }
      }
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .block-print-item {
    padding: 20px 0;
  }
}
