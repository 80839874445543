@import '../../../styles/settings/colors';

@import '../../../styles/components/animations';

.time-line-background {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  flex-flow: row wrap;
  height: 100%;
  left: 0;
  position: absolute;
  pointer-events: none;
  top: 0;
  width: 100%;
  z-index: 3;

  svg {
    height: auto;
    left: 50%;
    opacity: 0.2;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) scale(2);
    width: auto;

    g {
      path {
        fill: $neutra-05;
        transition: all 1200ms $base-ease;
        transition-delay: 1s;
      }
    }
  }

  &[data-type="home"],
  &[data-type="tipo_1"] {
    svg {
      g {
        path {
          fill: $neutra-05;
        }
      }
    }
  }

  &[data-type="tipo_2"],
  &[data-type="tipo_4"] {
    svg {
      g {
        path {
          fill: $primary-light;
        }
      }
    }
  }

  &[data-type="tipo_3"] {
    svg {
      g {
        path {
          fill: $secondary-dark;
        }
      }
    }
  }
}