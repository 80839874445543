/* --- RESET --- */
button,
input[type="text"],
input[type="submit"],
input[type="email"],
input[type="number"],
input[type="time"],
input[type="date"],
input[type="tel"],
input[type="file"],
input[type="password"],
textarea,
select,button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-image: none;
  border-radius: 0;
}

input[type="password"],
input[type="text"],
input[type="submit"],
input[type="time"],
input[type="date"],
input[type="email"],
input[type="number"],
input[type="file"],
input[type="tel"],
textarea,
select {
  outline: none;
  line-height: 1em;
}