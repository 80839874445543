.gallery-thumbs-item {
  cursor: pointer;
  float: left;
  height: 100%;
  position: relative;
  width: 100%;
  vertical-align: top;

  img {
    height: auto;
    float: left;
    height: 100%;
    width: 100%;
    vertical-align: top;
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .gallery-thumbs-item {
    width: 390px;
  }
}