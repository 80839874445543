@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/components/animations';
@import '../../../styles/components/forms/buttons';

@import '../../../styles/tools/mixins';

.highlights-arrow {
  @extend %btn-clear;
  background-color: rgba($secondary-dark-100, 0.2);
  height: calc(100% + 25px);
  position: absolute;
  top: 0;
  transition: background-color 600ms $base-ease;
  width: 168px;

  .icon {
    @include rem('font-size', 23px);
    color: $neutra;
    left: calc(50% + 10px);
    position: absolute;
    top: calc(50% + 10px);
    transform: translate(-50%, -50%);
  }

  &.slick-prev {
    right: 100%;
  }

  &.slick-next {
    left: 100%;

    .icon {
      left: calc(50% - 10px);
    }
  }

  &:hover {
    background-color: rgba($secondary-dark-100, 0.7);
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .highlights-arrow {
    background-color: rgba($secondary-dark-100, 0.7);
    height: 228px;
    width: 60px;

    .icon {
      left: 50%;
    }

    &.slick-next {
      .icon {
        left: 50%;
      }
    }
  }
}