@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/components/forms/buttons';

@import '../../../../styles/tools/mixins';

.gallery-thumbs-arrow {
  @extend %btn-clear;
  background-color: transparent;
  bottom: 0;
  height: 50px;
  position: absolute;
  width: 110px;
  z-index: 2;

  .icon {
    @include rem('font-size', 20px);
    color: $neutra;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.slick-prev {
    left: 0;
  }

  &.slick-next {
    right: 0;
  }
}
