@import '../../../styles/settings/colors';

.tabs-background {
  max-width: none;
  height: 740px;
  left: 50%;
  opacity: 0.6;
  position: absolute;
  transform: translate(-50%, 0);
  top: 40%;
  width: 100vw;

  svg {
    path {
      fill: $neutra-05;
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .tabs-background {
    bottom: 0;
    height: auto;
    top: initial;

    svg {
      transform: scale(2) !important;
    }
  }
}