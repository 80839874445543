
@import '../../../styles/components/animations';

.webdoor-slider {
  height: auto;
  padding-bottom: 56.25%;
  width: 100%;
  z-index: 2;

  &[data-direction="prev"] {
    .webdoor-item {
      &[data-active="true"] {
        animation: slide-in-right 0.5s $base-ease both;
        transform: translateX(100%);
      }

      &[data-last="true"] {
        animation: slide-out-left 0.5s $base-ease both;
        transform: translateX(0%);
      }
    }
  }

  &[data-direction="next"] {
    .webdoor-item {
      &[data-active="true"] {
        animation: slide-in-left 0.5s $base-ease both;
        transform: translateX(-100%);
      }

      &[data-last="true"] {
        animation: slide-out-right 0.5s $base-ease both;
        transform: translateX(0%);
      }
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .webdoor-slider {
    padding-bottom: 0;
  }
}