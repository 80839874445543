.view-videos {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 64px 0;

    .videos--content {
        max-width: 1450px;
        padding: 0 24px;
        width: 100%;
        margin: 0 auto;

        .videos--list {
            display: flex;
            gap: 20px;
            flex-wrap: wrap;
            list-style: none;
            padding: 0;

            @media screen and (max-width: 1023px) {
                justify-content: center;
            }

            li {
                width: calc((100% - 40px) / 3);
                background-color: #475C7A;
                display: flex;
                flex-direction: column;
                filter: drop-shadow(0px 4px 40px rgba(25, 38, 57, 0.24));
                margin-bottom: 20px;
                cursor: pointer;
                @media screen and (max-width: 1023px) {
                    width: calc((100% - 20px) / 2);
                }
                @media screen and (max-width: 767px) {
                    width: 100%;
                }

                .thumb {
                    position: relative;
                    svg {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        display: block;
                        transform: translate(-50%, -50%);
                        width: 88px;
                        height: 88px;
                    }
                }
                
                img {
                    width: 100%;
                }

                .description {
                    padding: 32px;
                    text-align: left;
                    
                    h3 {
                        color: white;
                        margin: 0 0 24px;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 18px;
                        text-transform: uppercase;
                        font-family: "TheSans", sans-serif;
                    }

                    p {
                        color: #FFF;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px; /* 150% */
                        margin: 0;
                        font-family: "TheSans", sans-serif;

                        a {
                            color: #EF7D05;
                            text-decoration: none;
                            font-weight: bold;
                        }
                    }

                }
            }
        }
    }

    .modal-video {
        background: rgba(0, 0, 0, 0.60);
        display: none;
        height: 100vh;
        left: 0;
        position: fixed;
        text-align: center;
        justify-content: center;
        align-items: center;
        top: 0;
        width: 100vw;
        z-index: 9999;

        &.active {
            display: flex;
        }

        .modal-video--content {
            width: 100%;
            max-width: 1156px;
            margin: 0 auto;
            max-height: 100vh;
            display: flex;
            flex-direction: column;

            .close {
                border: none;
                background-color: transparent;
                color: white;
                margin-bottom: 19px;
                margin-left: auto;
                cursor: pointer;
                span {
                    font-size: 35px;
                }
            }

            iframe {
                width: 100%;
                height: calc(100vh - 60px);
                max-height: 650px;
            }
        }
    }
}