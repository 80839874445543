@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/components/forms/buttons';

@import '../../../styles/tools/mixins';

.card-collapse {
  &-info {
    background-color: $neutra;
    float: right;
    max-width: 675px;
    position: relative;
    text-align: left;
    width: 100%;

    &--body,
    &--footer {
      float: left;
      position: relative;
      width: 100%;
      vertical-align: top;
    }

    &--body {
      border: none;
      border-radius: 0 0 5px 5px;
      box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.16);
      overflow: hidden;
      padding: 45px 70px 65px;
      text-align: left;

      .description,
      .title-text {
        float: left;
        margin: 0;
        position: relative;
        text-align: left;
        width: 100%;
        vertical-align: top;
      }

      .description {
        @include font($font-default, 16px, 400, $neutra-04);
        line-height: 1.6em;
        height: auto;
        overflow: hidden;

        > p {
          opacity: 0;
          display: inline-block;
          margin: 0 0 20px;

          &:first-child {
            opacity: 1;
          }
        }
      }

      .title-text {
        @include font($font-default, 40px, 700, $neutra-04);
        margin: 0 0 10px;
        padding: 0;
      }

      &-content {
        transition: max-height .4s ease, height .4s ease;
      }
    }

    &--footer {
      top: -16px;

      .btn-collapse {
        @extend %btn-clear;
        background-color: $neutra;
        border: 1px solid $neutra-02;
        border-radius: 5px;
        box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.16);
        height: 32px;
        right: 40px;
        padding: 0 9px;
        position: absolute;
        width: 32px;
        z-index: 9;

        .icon {
          @include rem('font-size', 21px);
          color: $secondary-color;
          float: left;
          position: relative;
          transform: rotate(-90deg);
          transform-origin: center;
          z-index: 2;
        }

        &[data-active="true"] {
          .icon {
            transform: rotate(90deg);
          }
        }
      }

      &[data-active="false"] {
        .btn-collapse {
          display: none;
        }
      }
    }
  }

  &[data-collapse="true"] {
    .card-collapse {
      &-info {
        &--body {
          .description {
            > p {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .card-collapse {
    &-info {
      float: none;
      margin: 0 auto;
      max-width: 480px;

      &--body {
        padding: 65px 30px;
      }
    }
  }
}