@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.highlights-item {
  padding: 5px;
  position: relative;
  width: auto;
  vertical-align: top;

  .content,
  .image {
    float: left;
    position: relative;
    vertical-align: top;
  }

  .content {
    width: calc(100% - 70px);
    max-width: 210px;
    text-align: left;
    
    p {
      @include font($font-default, 22px, 300, $neutra);
      margin: 0;
      float: left;
      line-height: 1em;
      width: 100%;
      text-transform: uppercase;

      &:first-child {
        font-weight: 700;
      }
    }
  }

  .image {
    opacity: 1;
    margin: 0 20px 0 0;
    width: 50px;

    img {
      height: 50px;
      width: auto;
    }
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .highlights-item {
    padding: 5px 10px;
    width: 100%;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .highlights-item {
    padding: 5px 20px;
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .highlights-item {
    padding: 5px;
  }
}