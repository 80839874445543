@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.ships-tabs-background {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.32) 100%), $primary-color;
  box-shadow: 0px 4px 40px rgba(25, 38, 57, 0.24);
  border-radius: 5px;
  left: 0;
  height: 350px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;

  .title {
    @include font($font-default, 60px, 600, $neutra);
    bottom: 0;
    left: 60px;
    margin: 0;
    padding: 20px 0;
    position: absolute;
    transform: rotate(-90deg);
    transform-origin: left center;
  }

  &:after {
    background-color: $secondary-color;
    content: '';
    height: 6px;
    left: 0;
    position: absolute;
    top: calc(50% - 40px);
    transform: translate(0, -50%);
    width: 80px;
  }

  svg {
    opacity: 0.24;

    path {
      fill: $neutra-05;
    }
  }

  &[data-type="tipo_2"] {
    background: $secondary-color;

    &:after {
      background-color: $primary-light;
    }

    svg {
      path {
        fill: $primary-light;
      }
    }
  }

  &[data-type="tipo_3"] {
    background: $neutra-05;

    .title {
      color: $primary-light;
    }

    &:after {
      background-color: $primary-light;
    }

    svg {
      path {
        fill: $primary-light;
      }
    }
  }

  &[data-type="tipo_4"] {
    background: $secondary-dark;

    &:after {
      background-color: $primary-color;
    }

    svg {
      path {
        fill: $neutra-05;
      }
    }
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .ships-tabs-background {
    &[data-min="base"] {
      .title {
        @include rem('font-size', 20px);
        bottom: initial;
        left: 20px;
        padding: 0;
        top: 20px;
        transform: rotate(0deg);
      }

      &[data-type="tipo_1"] {
        .title {
          color: $secondary-color;
        }
      }
  
      &[data-type="tipo_2"] {
        .title {
          color: $neutra;
        }
      }
  
      &[data-type="tipo_3"] {
        .title {
          color: $primary-dark;
        }
      }
  
      &[data-type="tipo_4"] {
        .title {
          color: $neutra-05;
        }
      }
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .ships-tabs-background {
    .title {
      left: 40px;
      padding: 0;
    }
    &:after {
      top: 50px;
    }

    &[data-min="relations"],
    &[data-min="base"] {
      .title {
        left: 30px;
      }
    }

    &[data-min="relations"] {
      height: 530px;

      .title {
        @include rem('font-size', 20px);
        bottom: initial;
        left: 20px;
        padding: 0;
        top: 150px;
        transform: rotate(0deg);
      }

      &[data-type="tipo_1"] {
        .title {
          color: $secondary-color;
        }
      }
  
      &[data-type="tipo_2"] {
        .title {
          color: $neutra;
        }
      }
  
      &[data-type="tipo_3"] {
        .title {
          color: $primary-dark;
        }
      }
  
      &[data-type="tipo_4"] {
        .title {
          color: $neutra-05;
        }
      }

      &:after {
        top: 65%;
      }
    }
  }
}
