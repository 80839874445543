@import '../../../styles/settings/colors';
@import '../../../styles/settings/grid';

@import '../../../styles/components/animations';
@import '../../../styles/components/forms/buttons';

.timeline-home {
  background-color: transparent;
  float: left;
  height: 100vh;
  text-align: center;
  width: 100vw;
  vertical-align: top;
  
  @media only screen and (min-width: 769px) {
    left: 0;
    position: absolute;
    top: 0;
    z-index: 9999;
    &[data-active="false"] {
      pointer-events: none;
    }
  }

  &--container {
    float: none;
    height: 100%;
    margin: 0 auto;
    pointer-events: none;
    max-width: $container-width;
    text-align: left;
    width: 100%;
    vertical-align: top;

    .btn-next {
      @extend %btn-clear;
      animation: mover 2000ms $base-ease 0s infinite forwards;
      bottom: 60px;
      left: 50%;
      opacity: 1;
      position: absolute;
      transform: translate(-50%, 0);
      transition: all 800ms $base-ease;

      .icon {
        @include rem('font-size', 20px);
        color: $neutra;
        float: left;
        transform: rotate(-90deg);
      }
    }
  }

  &[data-active="false"] {
    .btn-next {
      animation: none;
      opacity: 0;
      pointer-events: none;
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .timeline-home {
    left: initial;
    height: 100vh;
    position: relative;
    top: initial;
    z-index: 10;

    &--container {
      max-width: 315px;
    }

    &[data-active] {
      .btn-next {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}
