@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.list-simple {
  float: left;
  margin: 0 0 30px;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  &--list {
    margin: 0 auto;
    list-style: none;
    max-width: 635px;
    padding: 0;
    text-align: left;
    vertical-align: top;
  }

  &--item {
    float: left;
    margin: 0 0 12px;
    padding-left: 30px;
    position: relative;
    width: 100%;
    vertical-align: top;

    > p {
      @include font($font-default, 16px, 300, $neutra-06);
      float: left;
      line-height: 1.6em;
      margin: 0 0 10px;
      vertical-align: top;
      &:last-child {
        margin-bottom: 0;
      }
    }

    &:before {
      background-color: transparent;
      border: 1px solid $secondary-color;
      border-radius: 100%;
      content: '';
      height: 8px;
      left: 0;
      position: absolute;
      top: 6px;
      width: 8px;
    }
    a {
      color: $secondary-color;
      text-decoration: none;
      font-weight: bold;
    }
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .list-simple {
    padding: 0 30px;
    
    &--list {
      max-width: 550px;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .list-simple {
    padding: 0 30px;
    
    &--list {
      max-width: 500px;
    }
  }
}