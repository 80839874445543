@import '../../../../styles/settings/grid';

.list-group-content {
  display: flex;
  margin: 0 auto;
  max-width: $page-width;
  text-align: left;
  position: relative;
  width: 100%;
  vertical-align: top;
  @media only screen and (max-width : 1023px) {
    flex-wrap: wrap;
  }
}