.blog--related-posts {
    width: 100%;

    ul {
        width: 100%;
        li {
            a {
                color: #EF7D05;
                font-weight: bold;
                text-decoration: none;
            }
        }
    }
}