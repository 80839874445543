/* --- SELECT ITEM --- */
@import '../../../../../styles/settings/colors';
@import '../../../../../styles/settings/fonts';

@import '../../../../../styles/components/animations';

@import '../../../../../styles/tools/mixins';

.select-item {
  border: none;
  border-bottom: 1px solid $neutra-03;
  float: left;
  line-height: 1em;
  padding: 13px 15px;
  position: relative;
  text-align: left;
  width: 100%;
  user-select: none;
  vertical-align: top;

  .text {
    @include font($font-default, 14px, 400, $neutra-03);
    float: left;
    line-height: 1em;
    margin: 0;
    position: relative;
    text-align: left;
    width: 100%;
    white-space: nowrap;
  }

  &[data-active="true"] {
    border: 2px solid $primary-color;
    background-color: $primary-color;

    .text {
      color: $neutra;
    }
  }

  &:hover {
    background-color: rgba($primary-color, 0.1);

    .text {
      color: $primary-color;
    }
  }
}
