.webdoor {
  float: left;
  height: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
  vertical-align: top;

  &:before {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.58) 0%, rgba(0, 0, 0, 0) 100%);
    content: '';
    height: 235px;
    left: 0;
    position: absolute;
    pointer-events: none;
    top: 0;
    width: 100%;
    z-index: 4;
  }
}

@media only screen and (max-width: 576px) {
  .webdoor {
    &:before {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 98.96%);
      height: 100%;
      pointer-events: none;
    }
  }
}