@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.features-item {
  background-color: transparent;
  float: left;
  height: 100%;
  pointer-events: auto;
  text-align: left;
  position: relative;
  width: 100%;

  &--info,
  &--image {
    position: relative;
    width: 100%;
    vertical-align: top;
  }

  &--info {
    padding: 25px 45px 40px;

    .description,
    .link-item,
    .title {
      margin: 0;
      position: relative;
      width: 100%;
      vertical-align: top;
    }

    .description {
      @include font($font-default, 16px, 400, $neutra);
      background-color: transparent;
      
      p {
        background-color: transparent;
        margin: 0 0 10px;
        line-height: 1.4em;
      }
    }

    .link-item {
      @include font($font-default, 12px, 800, $neutra);
      margin: 20px 0 30px;
      text-decoration: none;
      text-transform: uppercase;
      width: auto;
    }

    .title {
      @include font($font-default, 40px, 600, $neutra);
      margin: 0 0 10px;
      line-height: 1em;
    }
  }

  &--image {
    padding: 25px 45px 0;

    img {
      float: left;
      position: relative;
    }
  }

  &:before {
    content: '';
    height: 200%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &[data-type="1"] {
    background-color: $secondary-color;

    &:before {
      background-color: $secondary-color;
    }
  }

  &[data-type="2"] {
    background-color: $secondary-dark-200;
  
    &:before {
      background-color: $secondary-dark-200;
    }
  }

  &[data-type="3"] {
    background-color: $secondary-dark;

    &:before {
      background-color: $secondary-dark;
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .features-item {
    width: 400px;
  }
}