@import '../../../../styles/settings/colors';

.timeline-item-background {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  svg {
    right: 0;
    opacity: 0.35;
    position: absolute;
    top: 0;
    transform: scale(1.3);
    transform-origin: top left;

    path {
      fill: $neutra-05;
    }
  }

  &[data-type="tipo_1"] {
    svg {
      path {
        fill: $neutra-05;
      }
    }
  }

  &[data-type="tipo_2"],
  &[data-type="tipo_3"] {
    svg {
      path {
        fill: $primary-light;
      }
    }
  }
}