@import '../../styles/settings/grid';

.card-collapse {
  float: left;
  margin: 60px 0;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  &--container {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    flex-flow: row wrap;
    float: none;
    max-width: $page-width;
    margin: 0 auto;
    padding: 0 20px;
    position: relative;
    width: 100%;
  }
}
