@import '../../../../../styles/settings/colors';
@import '../../../../../styles/settings/fonts';

@import '../../../../../styles/tools/mixins';

.select-title {
  background-color: $neutra;
  border-radius: 0;
  float: left;
  position: relative;
  width: 100%;
  vertical-align: top;
  z-index: 2;

  &--content {
    align-items: center;
    display: inline-flex;
    justify-content: flex-start;
    flex-flow: row nowrap;
    float: left;
    height: 100%;
    margin: 0;
    padding: 17px 15px;
    position: relative;
    width: 100%;
    user-select: none;
    vertical-align: top;

    .icon,
    .text {
      float: left;
      position: relative;
    }

    .icon {
      @include rem('font-size', 15px);
      color: $neutra-03;
      right: 25px;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%) rotate(90deg);

      &[data-active="true"] {
        transform: translate(0, -50%) rotate(-90deg);
      }
    }

    .text {
      @include font($font-default, 14px, 400, $neutra-03);
      float: left;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;

      &[data-type="true"] {
        color: $secondary-color;
      }
    }
  }
}
