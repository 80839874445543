@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';
@import '../../../styles/settings/grid';

@import '../../../styles/components/animations';
@import '../../../styles/tools/mixins';

.header {
  &-top {
    background: linear-gradient(270deg, rgba(0, 0, 0, 0.48) 0%, rgba(0, 0, 0, 0) 78.81%);
    float: right;
    padding: 10px 20px;
    max-width: $page-width;
    text-align: right;
    pointer-events: none;
    position: relative;
    transition: all 200ms $base-ease;
    width: 100%;
    vertical-align: top;
    z-index: 10;

    &--item,
    &--list,
    &--toggle {
      position: relative;
      vertical-align: top;
    }

    &--item {
      float: left;
      margin: 0 15px;
      pointer-events: auto;
      position: relative;
      width: auto;
      vertical-align: top;

      .link-item {
        @include font($font-default, 14px, 400, $neutra);
        float: left;
        padding: 0;
        text-decoration: none;
        vertical-align: top;
      }

      &:before {
        background-color: $neutra;
        content: '';
        border-radius: 100%;
        height: 6px;
        right: calc(100% + 13px);
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        width: 6px;
      }

      &:first-child {
        &:before {
          display: none;
        }
      }
    }

    &--list {
      margin: 0;
      padding: 6px 0 0;
      text-align: left;
    }

    &--toggle {
      height: 19px;
      padding: 0 5px;
      pointer-events: auto;
      text-align: center;
      width: 40px;

      .icon {
        @include rem('font-size', 19px);
        color: $neutra;

        &.icon-close {
          @include rem('font-size', 20px);
          display: none;
        }
      }

      &[data-active="true"] {
        .icon {
          &.icon-hamburger {
            display: none;
          }

          &.icon-close {
            display: inline-block;
          }
        }  
      }
    }
  
    &:after {
      background: rgba(0, 0, 0, 0.48);
      content: '';
      height: 100%;
      left: 100%;
      position: absolute;
      top: 0;
      width: 50vw;
      z-index: 9;
    }
  }

  &[data-media="sm"],
  &[data-media="xs"],
  &[data-media="md"] {
    .header {
      &-top {
        background: transparent;
        padding: 53px 50px;
        width: 100%;

        &--toggle {
          .icon {
            color: $neutra;
          }
        }

        .search {
          &-btn {
            top: 0;
          }
        }

        .select-lang {
          top: 50%;
          margin: 0;
          right: 160px;
          position: absolute;
          transform: translate(0, -50%);
        }

        &:after {
          display: none;
        }
      }
    }

    &[data-active="true"],
    &[data-scroll="true"] {
      .header {
        &-top {
          background-color: $neutra;
          height: 60px;
          padding: 20px;

          &--toggle {
            .icon {
              color: $primary-color;
            }
          }

          .search {
            &-btn {
              top: 0;

              .icon {
                color: $primary-color;
              }
            }
          }

          .select-lang {
            bottom: initial;
            right: 130px;
            top: 50%;
          }
        }
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .header {
    &[data-media="xs"],
    &[data-media="sm"],
    &[data-media="md"] {
      .header-top {
        padding: 53px 20px;
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .header {
    .header-top {
      .select-lang {
        right: 130px !important;
      }
    }
  }
}