/* --- MIXINS --- */
@import '../settings/colors';

/* --- BOX BOXING --- */
@mixin box-display {
  box-sizing: border-box;
  display: inline-block;
}

/* --- FONT --- */
@mixin font($font: $font-default, $size: 12px, $weight: 400, $color: $black-900) {
  @include rem('font-size', $size);
  color: $color;
  font-family: $font;
  font-weight: $weight;
}

/* --- REM --- */
@mixin rem($property, $px_values) {
  $baseline_rem: calc($font-base-size / 1rem);
  #{$property}: $px_values;

  // If there is only one (numeric) value, return the property/value line for it.
  @if type-of($px_values) == 'number' {
    #{$property}: calc($px_values / $baseline_rem);
  }
  @else {
    $rem_values: ();

    @each $value in $px_values {
      @if $value == 0 {
        $rem_values: append($rem_values, $value);
      } @else {
        $rem_values: append($rem_values, calc($value / $baseline_rem));
      }

    }

    // Return the property and its list of converted values
    #{$property}: $rem_values;
  }
}

/* --- PARALLAX --- */
@mixin parallax-fix {
  .parallax-outer,
  .parallax-inner {
    float: left;
    position: relative;
    width: 100%;
    vertical-align: top;
  }
}

/* --- DELAY --- */
@mixin delay($rule, $number, $value) {
  @for $i from 1 to ($number + 1) {
    &:nth-child(#{$i}) {
      #{$rule}-delay: (#{$i * $value});
    }
  }
}

/* --- SUPPORT HOVER --- */
@mixin hover-supported {
  @media not all and (hover: none) {
    &:hover {
      @content;
    }
  }
}

/* --- SCROLL --- */
@mixin scrollV($width: 6px, $bg-color: $white-900, $bg-bar-color: $black-900) {
  &::-webkit-scrollbar-track {
    background-color: $bg-color;
  }

  &::-webkit-scrollbar {
    background-color: $bg-color;
    width: $width;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $bg-bar-color;
  }
}

@mixin scrollH($height: 6px, $bg-color: $white-900, $bg-bar-color: $black-900) {
  &::-webkit-scrollbar-track {
    background-color: $bg-color;
  }

  &::-webkit-scrollbar {
    background-color: $bg-color;
    height: $height;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $bg-bar-color;
  }
}
