@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.tabs-item {
  align-items: center;
  display: inline-flex;
  float: left;
  flex-flow: row wrap;
  justify-content: center;
  pointer-events: none;
  position: relative;
  width: 100%;
  vertical-align: top;

  &--info,
  &--image {
    float: left;
    position: relative;
    vertical-align: top;
  }

  &--info {
    margin: 0 0 10px;
    padding: 0 20px;
    text-align: left;
    width: calc(50% + 25px);

    .description,
    .link-item,
    .subtitle,
    .title {
      float: left;
      margin: 0;
      position: relative;
      width: 100%;
      vertical-align: top;
    }

    .description {
      @include font($font-default, 16px, 300, $neutra-04);
      line-height: 1.4em;

      p {
        margin: 0 0 10px;
      }
    }

    .link-item {
      margin: 20px 0 0;
      pointer-events: auto;

      .icon {
        @include rem('font-size', 13px);
        color: $secondary-color;
      }
    }

    .subtitle {
      @include font($font-default, 16px, 800, $neutra-04);
      text-transform: uppercase;
    }
    
    .title {
      @include font($font-default, 40px, 600, $neutra-04);
      margin: 0 0 10px;
      text-indent: -0.07em;
      text-transform: uppercase;
    }
  }

  &--image {
    mix-blend-mode: multiply;
    width: calc(50% - 25px);
    z-index: 2;

    img {
      float: left;
      height: auto;
      object-fit: cover;
      position: relative;
      width: 100%;
      vertical-align: top;
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .tabs-item {
    &--info,
    &--image {
      width: 100%;
    }

    &--image {
      margin: 0 0 60px;

      img {
        max-width: 700px;
      }
    }

    &--info {
      max-width: 440px;
      width: 100%;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .tabs-item {
    &--image {
      margin: 0;
      padding: 0 0 30px;

      img {
        max-width: none;
        width: 100%;
      }
    }
  }
}