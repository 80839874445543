@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/components/forms/buttons';
@import '../../../styles/components/icons';

@import '../../../styles/tools/mixins';

.card-image {
  align-items: center;
  display: inline-flex;
  float: left;
  height: 590px;
  flex-flow: row wrap;
  justify-content: center;
  max-width: 55.5%;
  padding: 0;
  position: relative;
  width: 100%;
  vertical-align: top;
  z-index: 2;

  &--background,
  &--gallery,
  &--item {
    position: relative;
  }

  &--background {
    background-color: $primary-color;
    border-radius: 5px 0 0 5px;
    right: 0;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: calc(100% - 60px);

    svg {
      height: 100%;
      right: 0;
      transform: scale(1.2);
      position: absolute;
      top: 0;
      width: 100%;

      .main {
        opacity: 0.4;
      }

      .line {
        fill: $neutra-05;
      }
    }
  }

  &--gallery {
    float: left;
    height: 100%;
    overflow: hidden;
    width: 100%;
    vertical-align: top;

    &--controls {
      bottom: 0;
      right: 0;
      position: absolute;
      width: calc(100% - 60px);

      .btn {
        @extend %btn-clear;
        background-color: rgba($neutra-04, 0.5);
        position: relative;
        width: 110px;
        vertical-align: top;

        .icon {
          @include rem('font-size', 20px);
          color: $neutra;
          float: left;
          padding: 15px 25px;
        }

        &.left {
          float: left;
        }

        &.right {
          float: right;
        }
      }
    }

    &--list {
      margin: 0;
      list-style: none;
      padding: 0;
      position: relative;
      width: 100%;
    }
  }

  &--item {
    display: none;
    height: 450px;
    right: 0;
    position: absolute;
    top: 0;
    width: calc(100% - 120px);
    z-index: 1;
    vertical-align: top;

    .image {
      float: left;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    &[data-current="true"] {
      animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      display: inline-block;
      z-index: 2;

      & + .card-image {
        &--item {
          display: inline-block;
        }
      }
    }
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .card-image {
    max-width: 750px;
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .card-image {
    height: 450px;
    max-width: none;
    padding: 0 20px;
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .card-image {
    height: 400px;
    padding: 0;

    &--background {
      svg {
        bottom: 0;
        height: auto;
        top: initial;
      }
    }

    &[data-multiple="false"] {
      .card-gallery {
        .card-gallery--item {
          width: calc(100% - 30px);
        }
      }
    }
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  1% {
    transform: translateX(100%);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
