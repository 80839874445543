@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.select-min {
  background-color: transparent;
  border: none;
  border-radius: 5px;
  float: left;
  position: relative;
  text-align: left;
  vertical-align: top;
  width: 100%;
  z-index: 9;

  &-control {
    float: left;
    margin: 0;
    pointer-events: auto;
    padding: 0;
    width: 100%;
    vertical-align: top;

    &[data-disabled="true"] {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &--list {
    background-color: $neutra;
    box-shadow: 0px 4px 40px rgba(25, 38, 57, 0.24);
    border-radius: 4px;
    display: none;
    left: -1px;
    max-height: 300px;
    margin: 0;
    min-width: 220px;
    overflow: hidden;
    overflow-y: auto;
    list-style: none;
    padding: 20px 15px;
    position: absolute;
    text-align: left;
    top: calc(100% + 5px);
    width: calc(100% + 2px);
    vertical-align: top;
    z-index: 1;

    &--item {
      float: left;
      position: relative;
      width: 100%;
      vertical-align: top;

      &:first-child {
        .select-min-item {
          padding-top: 0;
        }
      }
    }

  }

  &[data-active="true"] {
    z-index: 99999;

    .select-min {
      &--list {
        display: inline-block;
      }

      &-title {
        background-color: $neutra-02;

        .icon {
          color: $neutra-03;
        }
      }
    }
  }
}
