@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.select-lang-item {
  border: none;
  border-radius: 5px;
  padding: 20px 12px;
  position: relative;
  text-align: left;
  width: 100%;

  .icon-flag,
  .text {
    float: left;
    position: relative;
    vertical-align: top;
  }

  .icon-flag {
    margin: 0 10px 0 0;
    width: 24px;
  }

  .text {
    @include font($font-default, 20px, 600, $primary-color);
    margin: 0;
    line-height: 1.2em;
    text-align: left;
    width: calc(100% - 34px);
  }
}