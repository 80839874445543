@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/components/animations';
@import '../../../../styles/components/forms/buttons';

@import '../../../../styles/tools/mixins';

.gallery-modal-info {
  animation: fade-top-in 0.3s 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  left: 50%;
  padding: 30px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;

  .btn-item {
    @extend %btn-clear;
    left: 30px;
    outline: none;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 2;

    .icon {
      @include rem('font-size', 20px);
      color: $neutra;
    }
  }

  .text {
    @include font($font-default, 18px, 400, $neutra);
    float: none;
    margin: 0;
    line-height: 1em;
    padding: 0 0 0 70px;
    position: relative;
    text-align: left;
    text-transform: uppercase;
    width: 100%;
    vertical-align: top;
    z-index: 1;

    &:empty {
      display: none;
    }
  }

  @keyframes fade-top-in {
    0% {
      opacity: 0;
      transform: translate(0px, 50%);
    }
    100% {
      opacity: 1;
      transform: translate(0px, 0px);
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .gallery-modal-info {
    position: relative;
  }
}