@import "../../../../styles/settings/grid";

@import '../../../../styles/tools/mixins';

.list-indicators-container {
  max-width: $page-width;
  position: relative;
  text-align: center;
  width: 100%;

  &--background,
  &--item,
  &--list {
    position: relative;
    vertical-align: top;
  }

  &--background {
    @include parallax-fix;
    bottom: 0;
    left: 0;
    height: 100%;
    position: absolute;
    width: 100%;

    svg {
      .st0 {
        opacity: 0.5;
      }

      .st1 {
        opacity: 0.1;
        fill: $neutra-07;
        enable-background: new;
      }

      .st2 {
        fill: none;
        stroke: $neutra-05;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-dasharray: 4;
      }

      .st3 {
        fill: none;
        stroke: $secondary-color;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-dasharray: 4;
      }

      .st4 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: $neutra;
      }

      .st5 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: #56c65a;
      }

      .st6 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: $neutra;
        filter: url(#Adobe_OpacityMaskFilter);
      }

      .st7 {
        mask: url(#mask1_1_);
      }

      .st8 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: $neutra-05;
      }

      .st9 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: $neutra;
        filter: url(#Adobe_OpacityMaskFilter_1_);
      }

      .st10 {
        mask: url(#mask2_1_);
      }

      .st11 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: $neutra;
        filter: url(#Adobe_OpacityMaskFilter_2_);
      }

      .st12 {
        mask: url(#mask3_1_);
      }

      .st13 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: $neutra;
        filter: url(#Adobe_OpacityMaskFilter_3_);
      }

      .st14 {
        mask: url(#mask4_1_);
      }
      .st15 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: $neutra;
        filter: url(#Adobe_OpacityMaskFilter_4_);
      }

      .st16 {
        mask: url(#mask5_1_);
      }

      .st17 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: $neutra;
        filter: url(#Adobe_OpacityMaskFilter_5_);
      }

      .st18 {
        mask: url(#mask6_1_);
      }

      .st19 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: $secondary-light-100;
      }

      .st20 {
        fill: none;
        stroke: #5873b5;
      }
    }
  }

  &--item {
    float: left;
    position: relative;
    width: 100%;
  }

  &--list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0;
    list-style: none;
    padding: 0;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .list-indicators-container {
    &--item {
      padding: 0 60px;

      &:nth-child(2n+1) {
        .list-indicators-item {
          float: right;
        }
      }

      &:nth-child(2n+2) {
        .list-indicators-item {
          float: left;
        }
      }
    }

    &--list {
      grid-template-columns: 1fr 1fr;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .list-indicators-container {
    &--item {
      padding: 0;
    }
  }
}
