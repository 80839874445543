@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/components/forms/buttons';
@import '../../../styles/components/icons';

@import '../../../styles/tools/mixins';

.card-gallery {
  float: left;
  height: 450px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
  vertical-align: top;
  z-index: 1;

  &--item {
    display: none;
    height: 450px;
    left: 0;
    position: absolute;
    top: 0;
    width: calc(100% - 120px);
    z-index: 1;
    vertical-align: top;

    .image {
      float: left;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    &[data-current="true"] {
      display: inline-block;
      z-index: 2;
    }
  }

  &[data-direction="none"],
  &[data-direction="prev"] {
    .card-gallery {
      &--item {
        &[data-current="true"] {
          animation: slider-prev 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        }

        &[data-last="true"] {
          animation: slider-prev-last 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          display: inline-block;
        }

        &[data-next="true"] {
          animation: slider-prev-last 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          display: inline-block;
        }
      }
    }
  }

  &[data-direction="next"] {
    .card-gallery {
      &--item {
        &[data-current="true"] {
          animation: slider-next 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        }

        &[data-last="true"] {
          animation: slider-next-last 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          display: inline-block;
        }
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .card-gallery {
    &--item {
      height: 340px;
    }
  }
}

@keyframes slider-prev {
  0% {
    transform: translateX(calc((100% - 100px) * -1));
  }
  1% {
    transform: translateX(calc((100% - 100px) * -1));
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes slider-prev-last {
  0% {
    transform: translateX(120px);
  }
  1% {
    transform: translateX(120px);
  }
  100% {
    transform: translateX(calc(100% + 30px));
  }
}

@keyframes slider-next {
  0% {
    transform: translateX(calc(100% + 30px));
  }
  1% {
    transform: translateX(calc(100% + 30px));
  }
  100% {
    transform: translateX(120px);
  }
}

@keyframes slider-next-last {
  0% {
    transform: translateX(0);
  }
  1% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc((100% - 100px) * -1));
  }
}
