@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/components/forms/buttons';

@import '../../../styles/tools/mixins';

.client-slider-arrow {
  @extend %btn-clear;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);

  &.slick-prev {
    left: -20px;
  }
   
  &.slick-next {
    right: -20px;
  }

  .icon {
    @include rem('font-size', 23px);
    background: transparent;
    color: $neutra-03;
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .client-slider-arrow {
    &.slick-prev {
      left: -30px;
    }
     
    &.slick-next {
      right: -30px;
    }
  }
}