@import '../../styles/settings/colors';

@import '../../styles/components/forms/buttons';

@import '../../styles/components/animations';
@import '../../styles/tools/mixins';

.select-lang {
  position: relative;
  pointer-events: auto;
  transition: all 300ms $base-ease;
  vertical-align: top;
  z-index: 999;

  &--title {
    @extend %btn-clear;
    background-color: $neutra;
    border-radius: 5px;
    border: 1px solid $neutra-02;
    float: left;
    padding: 0;
    vertical-align: top;

    .icon {
      @include rem('font-size', 5px);
      color: $primary-color;
      right: 10px;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
    }

    .text {
      @include font($font-default, 13px, 700, $neutra-04);
      float: left;
      margin: 0;
      padding: 5px 30px 5px 10px;
      position: relative;
      vertical-align: top;
    }
  }

  &[data-active="true"] {
    .select-lang {
      &--title {
        background-color: $neutra-02;
      }
    }
  }
}