@import '../../../styles/settings/colors';
@import '../../../styles/components/animations';

.gallery-modal {
  float: left;
  position: relative;
  width: 100%;
  vertical-align: top;

  &-modal {
    background-color: rgba($neutra-04, 0.95);
    left: 0;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100vw;
    vertical-align: top;
    z-index: 999;
  }
}