@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';
@import '../../../../styles/settings/grid';

@import '../../../../styles/tools/mixins';

.ships-item-info {
  float: none;
  margin: 0 auto;
  max-width: $page-width;
  padding: 60px 20px 0;
  position: relative;
  text-align: left;
  width: 100%;
  vertical-align: top;

  &--image {
    float: left;
    padding: 60px;
    position: relative;
    width: calc(100% - 500px);

    .ships-tabs-background {
      box-shadow: none;
    }

    .image {
      border-radius: 5px;
      filter: drop-shadow(0px 4px 40px rgba(25, 38, 57, 0.24));
      float: right;
      width: 80%;
    }
  }

  &--attr {
    float: right;
    padding: 0 20px 0 0;
    width: 480px;


    &--footer {
      float: left;
      position: relative;
      width: 100%;
      vertical-align: top;
    }

    .ships-card-modal {
      box-shadow: none;
      display: inline-block;
      filter: none;
      left: initial;
      opacity: 1;
      padding: 10px 40px;
      position: relative;
      top: initial;
      transform: initial;
      width: 100%;
    }

    .btn-border {
      border: none;
    }

    .button-download {
      float: right;
      margin: 0;
      width: auto;

      .btn-item {
        .text {
          @include rem('font-size', 14px);
        }
      }
    }
  }

  &--title {
    float: left;
    position: relative;
    width: 100%;
    vertical-align: top;

    .title {
      @include font($font-default, 40px, 700, $neutra-04);
      float: left;
      margin: 0 0 50px;
      position: relative;
      text-align: left;
      width: 100%;
      vertical-align: top;

      &:after {
        background-color: $secondary-color;
        content: '';
        height: 4px;
        left: 0;
        position: absolute;
        top: calc(100% + 10px);
        width: 140px;
      }
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .ships-item-info {
    padding: 60px 0;
    text-align: center;

    &--attr {
      float: none;
      margin: 0 auto;
    }

    &--image {
      padding-right: 0;
      width: 100%;

      .image {
        height: auto;
        max-width: calc(100% - 70px);
        width: 100%;
      }
    }

    &--title {
      padding: 0 20px;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .ships-item-info {
    &--attr {
      float: left;
      padding: 0;
      width: 100%;

      .ships-card-modal {
        padding: 0 25px;
      }
    }

    &--image {
      margin: 0 0 60px;
      padding: 60px 20px 0;

      .ships-tabs-background {
        height: 265px;

        &:after,
        .title {
          display: none;
        }
      }

      .image {
        height: 240px;
        object-fit: cover;
        max-width: initial;
      }
    }
  }
}