@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.social-media-item {
  float: left;
  padding: 0 10px;
  position: relative;
  width: 100%;
  vertical-align: top;

  a {
    color: inherit;
    text-decoration: inherit;
  }

  &--content,
  &--title {
    float: left;
    position: relative;
    width: 100%;
    vertical-align: top;
  }

  &--content {
    padding: 5px 0;

    .description,
    .date {
      @include font($font-default, 16px, 400, $neutra);
      float: left;
      margin: 0;
      position: relative;
      width: 100%;
      vertical-align: top;

      > p {
        margin-top: 0;
      }
    }

    .date {
      @include rem('font-size', 14px);
      font-weight: 700;
      margin: 5px 0 10px;
    }
  }

  &--title {
    .image,
    .title {
      position: relative;
    }

    .image {
      border-radius: 5px;
      overflow: hidden;
      opacity: 1;
      width: 100%;
      vertical-align: top;
    }

    .title {
      @include font($font-default, 16px, 800, $neutra);
      height: 70px;
      margin: 0 0 5px;
      text-transform: uppercase;
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .social-media-item {
    width: 340px;
  }
}
