@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/components/animations';
@import '../../../styles/components/icons';

@import '../../../styles/tools/mixins';

.header {
  &-menu-container {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
  }

  &[data-media="xs"],
  &[data-media="sm"],
  &[data-media="md"] {
    .header {
      &-menu-container {
        background-color: $neutra;
        height: calc(100vh - 60px);
        max-width: 375px;
        left: -100%;
        overflow: hidden;
        overflow-y: auto;
        position: absolute;
        top: 60px;
        padding: 0;
        width: 100%;
      }
    }

    &[data-active="true"] {
      display: inline-block;
    }
  }
}