@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/components/animations';

@import '../../../../styles/tools/mixins';

.timeline-home {
  &-info {
    align-content: center;
    align-items: center;
    display: inline-flex;
    flex-flow: row wrap;
    float: left;
    height: 100%;
    position: relative;
    text-align: left;
    width: 55%;
    vertical-align: top;

    .description,
    .home,
    .title {
      float: left;
      opacity: 1;
      position: relative;
      transition: all 900ms $base-ease;
      transition-delay: 400ms;
      transform: translate(0, 0);
      width: 100%;
      vertical-align: top;
    }

    .description {
      @include font($font-default, 16px, 300, $neutra);
      
      > p {
        float: left;
        margin: 10px 0;
        line-height: 1.65em;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    .home {
      margin: 0;
      pointer-events: auto;
      top: -90px;
      width: auto;

      .icon {
        @include rem('font-size', 50px);
        color: $neutra;
        float: left;
      }
    }

    .title {
      @include font($font-default, 60px, 300, $neutra);
      margin: 0 0 30px;
    }
  }

  &[data-active="false"] {
    .timeline-home {
      &-info {
        .description,
        .home,
        .title {
          pointer-events: none;
          opacity: 0;
          transform: translate(0, -50%);
        }
      }
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .timeline-home {
    &-info {
      width: 100%;
    }

    &[data-active] {
      .timeline-home {
        &-info {
          .description,
          .home,
          .title {
            opacity: 1;
            transform: none;
          }

          .description {
            @include rem('font-size', 16px);
          }

          .home {
            margin: 0 0 60px;
            top: 0;
          }

          .title {
            @include rem('font-size', 24px);
            margin: 0 0 25px;
          }
        }
      }
    }
  }
}