@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/components/animations';

@import '../../../styles/tools/mixins';

.search-results {
  float: left;
  padding: 0 20px;
  text-align: center;
  width: 100%;
  vertical-align: top;

  &--empty,
  &--item,
  &--list,
  &--title {
    float: left;
    position: relative;
  }

  &--empty {
    @include font($font-default, 18px, 400, $primary-color);
    max-width: 960px;
    float: none;
    margin: 0 auto 45px;
    padding: 40px 0 0;
    text-align: left;
    text-transform: uppercase;
    width: 100%;

    &:before {
      background-color: $secondary-color;
      content: '';
      height: 4px;
      position: absolute;
      top: calc(100% + 10px);
      width: 40px;
    }
  }

  &--item {
    animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    margin: 30px 0;
  }

  &--list {
    list-style: none;
    float: none;
    margin: 0 0 30px;
    max-width: 960px;
    padding: 0;
    text-align: left;
    width: 100%;
  }

  &--title {
    margin: 60px 0 30px;
    text-align: center;
    width: 100%;

    .text,
    .term {
      float: left;
      margin: 0;
      position: relative;
      text-transform: uppercase;
      width: 100%;
    }

    .text {
      @include font($font-default, 18px, 400, $primary-dark);
    }

    .term {
      @include font($font-default, 40px, 600, $secondary-dark);
      quotes: "“" "”" "‘" "’";
      text-transform: none;

      &:before {
        content: open-quote;
      }

      &:after {
        content: close-quote;
      }
    }
  }
}