.blog-list {
  float: left;
  position: relative;
  width: 100%;
  vertical-align: top;

  &--container {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    width: 100%;
    vertical-align: top;
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .blog-list {
    padding: 0;
  }
}