.ships-item {
  float: left;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  .gallery-thumbs {
    .alice-carousel {
      padding-top: 20px;
    }
  }
}