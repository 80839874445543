@import '../../styles/settings/fonts';
@import '../../styles/settings/grid';

@import '../../styles/tools/mixins';

.blog {
  float: left;
  position: relative;
  width: 100%;
  vertical-align: top;

  .image-full {
    float: none;
    margin: 40px auto;
    text-align: center;
    max-width: $container-width;

    .image {
      float: none;
      width: auto;
    }
  }

  &--footer {
    float: none;
    max-width: $page-width;
    margin: 0 auto;
    padding: 40px 20px 90px;
    text-align: left;
    width: 100%;
    vertical-align: top;

    .btn-border {
      .icon,
      .text {
        float: left;
        position: relative;
        vertical-align: top;
      }

      .icon {
        @include rem('font-size', 10px);
        margin: 0 10px 0;
      }

      .text {
        margin: 0 10px 0 0;
        width: auto;
      }
    }
  }
}