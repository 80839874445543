@import '../../../styles/settings/grid';

.ships-gallery {
  float: left;
  margin: 30px 0;
  padding: 40px 80px 0;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  

  &[data-type="tipo_1"] {
    .ships-gallery-list--item {
      .ships-card {
        .title {
          color: $primary-color;
        }
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        .ships-card {
          .title {
            color: $neutra;
          }
        }
      }
    }
  }

  &[data-type="tipo_2"] {
    .ships-gallery-list--item {
      .ships-card {
        .title {
          color: $secondary-color;
        }
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        .ships-card {
          .title {
            color: $neutra;
          }
        }
      }
    }
  }

  &[data-type="tipo_3"] {
    .ships-gallery-list--item {
      .ships-card {
        .title {
          color: $neutra-05;
        }
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        .ships-card {
          .title {
            color: $neutra;
          }
        }
      }
    }
  }

  &[data-type="tipo_4"] {
    .ships-gallery-list--item {
      .ships-card {
        .title {
          color: $secondary-dark;
        }
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        .ships-card {
          .title {
            color: $neutra;
          }
        }
      }
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .ships-gallery {
    margin: 0;
    padding: 50px 0 0;
  }
}