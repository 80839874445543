@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/components/animations';

@import '../../../../styles/tools/mixins';

.ships-tab-item-home {
  height: 160px;
  padding: 0 0 20px 0;
  text-align: center;
  width: 160px;

  .image {
    float: none;
    filter: grayscale(100%);
    margin: 0 0 20px;
    opacity: 0.5;
    transition: all 400ms $base-ease;
  }

  .text {
    @include font($font-default, 16px, 800, $neutra-02);
    float: left;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
  }

  &:after {
    background-color: $primary-color;
    border-radius: 8px;
    bottom: -24px;
    content: '';
    height: 9px;
    left: 50%;
    position: absolute;
    transform: translate(-50%, 0);
    transition: width 400ms $base-ease;
    width: 0;
    z-index: 2;
  }

  &.active {
    .image {
      filter: grayscale(0);
      opacity: 1;
    }

    .text {
      color: $primary-color;
    }

    &:after {
      width: 130px;
    }
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .ships-tab-item-home {
    width: 140px;
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 768px) {
  .ships-tab-item-home {
    padding: 0 20px;
    width: 175px;

    &.active {
      &:after {
        left: 50%;
        width: 107px;
      }
    }
  }
}