@import '../../styles/settings/colors';

.menu {
  border: none;
  border-bottom: 1px solid $neutra-02;
  float: left;
  margin: 30px 0;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;
}