@import '../../../styles/settings/colors';

.ships-filters-category {
  background-color: rgba($neutra-02, 0.2);
  padding: 24px 0;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;
  z-index: 100;
  display: flex;
  justify-content: center;
  justify-items: center;
  flex-wrap: wrap;
}