@import '../../../../../styles/settings/colors';
@import '../../../../../styles/settings/fonts';

@import '../../../../../styles/tools/mixins';

.select-min-title {
  background-color: $neutra;
  border: 1px solid $neutra-02;
  border-radius: 5px;
  float: left;
  position: relative;
  width: auto;
  vertical-align: top;
  z-index: 2;

  &--content {
    align-items: center;
    display: inline-flex;
    justify-content: flex-start;
    flex-flow: row nowrap;
    float: left;
    height: 100%;
    line-height: 1em;
    margin: 0;
    padding: 9px 30px 8px 10px;
    position: relative;
    width: 100%;
    user-select: none;
    vertical-align: top;

    .icon,
    .text {
      float: left;
      position: relative;
    }

    .icon {
      @include rem('font-size', 5px);
      color: $primary-color;
      right: 10px;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);

      &[data-active="true"] {
        transform: translate(0, -50%);
      }
    }

    .text {
      @include font($font-default, 14px, 600, $neutra-03);
      float: left;
      margin: 0;
      overflow: hidden;
      text-transform: uppercase;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;

      &[data-type="true"] {
        color: $secondary-color;
      }
    }
  }
}
