/* --- GRID --- */
@import './colors';

$page-width: 1440px;
$container-width: 1220px;

html,
body,
body * {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
}

html,
body {
  float: left;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  vertical-align: top;
}

html {
  overflow: hidden;
  overflow-y: auto;
}

body {
  background-color: $neutra;
  height: auto;
  overflow-anchor: none;
  min-height: 100vh;
  overflow: hidden;

  > script {
    display: none;
  }
}

#root {
  float: left;
  min-height: 100vh;
  position: relative;
  width: 100%;
  vertical-align: top;
  z-index: 99;
}

.page {
  float: left;
  overflow: hidden;
  position: relative;
  width: 100%;
  vertical-align: top;
}