@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.block-ship-image {
  float: left;
  padding: 0 0 0 20px;
  position: relative;
  width: 590px;
  vertical-align: top;

  &--background {
    background-color: $primary-color;
    box-shadow: 0px 4px 40px rgba(25, 38, 57, 0.24);
    border-radius: 5px;
    height: 100%;
    right: 0;
    position: absolute;
    top: 0;
    width: calc(100% - 20px);

    svg {
      height: 100%;
      opacity: 0.3;
      width: 100%;

      g {
        path {
          fill: $neutra-05;
        }
      }
    }
  }

  .subtitle {
    @include font($font-default, 120px, 800, $neutra);
    bottom: -120px;
    left: 0;
    margin: 60px;
    line-height: 1em;
    position: absolute;
    transform: rotate(-90deg);
    transform-origin: left top;
  }

  .image {
    float: left;
    left: 50px;
    top: 45px;
  }

  &:after {
    background-color: $secondary-color;
    content: '';
    height: 8px;
    left: -25px;
    position: absolute;
    top: calc(50% - 20px);
    transform: translate(0, -50%);
    width: 90px;
  }

  &[data-type="tipo2"] {
    .block-ship-image {
      &--background {
        background-color: $secondary-color;

        svg {
          g {
            path {
              fill: $secondary-dark;
            }
          }
        }
      }
    }

    .subtitle {
      color: $primary-light;
    }

    &:after {
      background-color: $primary-light;
    }
  }

  &[data-type="tipo3"] {
    .block-ship-image {
      &--background {
        background-color: $neutra-05;

        svg {
          opacity: 0.2;

          g {
            path {
              fill: $primary-light;
            }
          }
        }
      }
    }

    .subtitle {
      color: $primary-light;
    }

    &:after {
      background-color: $primary-light;
    }
  }

  &[data-type="tipo4"] {
    .block-ship-image {
      &--background {
        background-color: $secondary-dark;

        svg {
          g {
            path {
              fill: $neutra-05;
            }
          }
        }
      }
    }

    &:after {
      background-color: $primary-color;
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .block-ship-image {
    height: 355px;
    width: 255px;

    .image {
      height: 330px;
      top: 20px;
      width: auto;
    }

    .subtitle {
      @include rem('font-size', 48px);
      bottom: -90px;
      left: -20px;
    }

    &:after {
      height: 3px;
      left: 0;
      width: 36px;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .block-ship-image {
    height: auto;
    padding: 0;
    width: 100%;

    &--background {
      width: 100%;
    }

    .image {
      height: 460px;
      width: auto;
    }

    &:after {
      height: 6px;
      left: -30px;
      width: 96px;
    }
  }
}