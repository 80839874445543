@import '../../../styles/settings/colors';
@import '../../../styles/settings/grid';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.block-print-info {
  align-items: center;
  display: inline-flex;
  flex-flow: row wrap;
  justify-content: center;
  height: 100%;
  position: relative;
  text-align: left;
  width: 50%;
  vertical-align: top;

  &--item,
  &--list,
  .title {
    float: left;
    position: relative;
    width: 100%;
    vertical-align: top;
  }

  &--item {
    float: left;
    position: relative;
    width: 100%;
    vertical-align: top;
  }

  &--list {
    list-style: none;
    margin: 0;
    padding: 20px 0;
  }

  .title {
    @include font($font-default, 40px, 700, $primary-dark);
    line-height: 1.1em;
    margin: 0;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .block-print-info {
    padding: 20px;

    .title {
      @include rem('font-size', 24px);
      font-weight: 400;
      line-height: 1.3em;
      text-transform: uppercase;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .block-print-info {
    padding: 20px 0;
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .block-print-info {
    padding: 40px 20px;
    width: 100%;
  }
}