@import '../../styles/settings/grid';

@import '../../styles/components/typography';

.tabs {
  margin: 40px 0;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;
  
  &--title {
    margin: 20px 0 40px;
    max-width: $page-width;
    width: 100%;

    .text {
      @extend %title-main;
      margin: 0;
      position: relative;
      width: 100%;
      vertical-align: top;
    }
  }
}
