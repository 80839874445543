@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/components/animations';
@import '../../styles/components/typography';

@import '../../styles/tools/mixins';

.loading {
  animation: scale-out 0.8s 1.5s $base-ease both;
  background-color: $neutra;
  left: 0;
  height: 100vh;
  opacity: 1;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 9999999;

  svg {
    @extend %logo;
    animation: scale-out-logo 0.5s 1s $base-ease both;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 400ms $base-ease;
    height: 40px;
    opacity: 1;
  }

  @keyframes scale-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes scale-out-logo {
    0% {
      transform: translate(-50%, -50%);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, calc(-50% + 10px));
      opacity: 0;
    }
  } 
}

.mini-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 400ms $base-ease;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  z-index: 1000;
  background: rgba(255,255,255, .3);
  width: 100%;
  height: 100%;

  span {
    height: 0;
    width: 12px;
    margin-right: 10px;
    border-radius: 12px;
    background: #EF7D05;
    animation: loading-scale .5s ease alternate infinite;

    &:last-child{
      margin-right: 0;
    }
    &:nth-child(2) {
      animation-delay: .2s;
    }
    &:nth-child(3) {
      animation-delay: .4s;
    }
  }
}

@keyframes loading-scale {
  from {
    height: 0;
  }
  to {
    height: 6vh;
  }
}