@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.ships-card-value {
  float: left;
  position: relative;
  width: auto;
  vertical-align: top;
  
  .icon {
    @include rem('font-size', 24px);
    color: $neutra-04;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }

  .text {
    @include font($font-default, 14px, 700, $black-900);
    line-height: 1.4em;
    margin: 0;
    padding: 0 5px 0 30px;
    width: auto;
  }
}