@import '../../styles/settings/colors';
@import '../../styles/components/typography';

@import '../../styles/tools/mixins';

.block-image-full {
  float: left;
  margin: 30px 0;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  &--image,
  &--info {
    position: relative;
    vertical-align: top;
  }

  &--image {
    @include parallax-fix;
    width: 100%;

    .image {
      float: left;
      height: auto;
      position: relative;
      width: 100%;
      vertical-align: top;
    }
  }

  &--info {
    float: none;
    margin: 60px 0 0;
    max-width: 700px;
    text-align: left;

    .description,
    .title {
      float: left;
      margin: 0;
      width: 100%;
      vertical-align: top;
    }

    .description {
      @include font($font-default, 16px, 300, $primary-dark);
      line-height: 1.4em;
      margin: 20px 0;

      > p {
        &:first-child {
          margin-top: 0;
        }
      }
    }

    .link-item {
      text-decoration: none;

      .icon {
        @include rem('font-size', 13px);
        color: $secondary-color;
      }
    }

    .title {
      @extend %title-super;
      font-weight: 700;
      color: $primary-dark;
    }
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .block-image-full {
    &--image {
      .image {
        object-fit: cover;
        height: 100%;
      }
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .block-image-full {
    &--info {
      padding: 0 90px;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .block-image-full {
    &--info {
      margin-top: 20px;
      padding: 0 30px;
    }
  }
}