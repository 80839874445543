@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.ships-card-modal {
  background-color: $neutra;
  display: none;
  filter: drop-shadow(-8px 4px 72px rgba(25, 38, 57, 0.8));
  left: 0;
  opacity: 0;
  padding: 25px;
  position: absolute;
  text-align: left;
  top: calc(50% + 30px);
  transform: translate(0, -50%);
  width: 450px;
  vertical-align: top;

  &--tags {
    float: left;
    list-style: none;
    margin: 0;
    padding: 0 0 15px;
    position: relative;
    width: 100%;
    vertical-align: top;

    &--item {
      background-color: $neutra-05;
      border-radius: 5px;
      margin: 0 6px 0 0;
      padding: 8px 5px;

      .tag {
        @include font($font-default, 14px, 600, $primary-dark);
        float: left;
        line-height: 1em;
        margin: 0;
        position: relative;
        width: 100%;
        vertical-align: top;
      }
    }

    &:empty {
      display: none;

      & + .ships-card-modal-prop {
        border-top: none;
        padding-top: 0px;
      }
    }
  }

  &--footer {
    float: left;
    padding: 20px 0 0;
    width: 100%;
    vertical-align: top;

    &:empty {
      display: none;
      padding: 0;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .ships-card-modal {
    padding: 15px;
    
    &--footer {
      .ships-item-info--attr--footer {
        text-align: center;

        .btn,
        .button-download {
          display: block;
          float: none;
        }

        .btn {
          margin-bottom: 20px;
        }
      }
    }
  }
}