.list-address-container {
  float: left;
  position: relative;
  text-align: center;
  width: 100%;

  &--item,
  &--list {
    float: left;
    padding: 0;
    position: relative;
    width: 100%;
    vertical-align: top;
  }

  &--list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    float: none;
    margin: 0 auto;
    max-width: 1090px;
    list-style: none;
    width: 100%;
  }

  &--slider {
    float: left;
    position: relative;
    width: 100%;
    vertical-align: top;
    
    .slick {
      position: relative;

      &-list,
      &-track {
        touch-action:pan-y;
      }
      
      &-list {
        float: left;
        overflow: hidden;
        position: relative;
        width: 100%;
      }
  
      &-slide {
        align-items: flex-start;
        display: inline-flex;
        justify-content: center;
        outline: none;
  
        > div {
          align-items: flex-start;
          display: inline-flex;
          justify-content: center;
          height: 100%;
          float: left;
          outline: none;
          padding: 0;
          position: relative;
          width: 100%;
        }
      }
  
      &-track {
        display: inline-flex;
        flex-flow: row nowrap;
        float: left;
        position: relative;
        width: 100%;
        vertical-align: top;
      }
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .list-address-container {
    &--item {
      width: 320px;
    }

    &--slider {
      margin: 0 0 60px;
    }
  }
}
