@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/components/forms/buttons';
@import '../../../styles/components/icons';

@import '../../../styles/tools/mixins';

.card-gallery-controls {
  bottom: 0;
  right: 0;
  position: absolute;
  width: calc(100% - 60px);
  z-index: 9;

  .btn {
    @extend %btn-clear;
    background-color: rgba($neutra-04, 0.5);
    position: relative;
    width: 110px;
    user-select: none;
    vertical-align: top;

    .icon {
      @include rem('font-size', 20px);
      color: $neutra;
      float: left;
      pointer-events: none;
      padding: 15px 25px;
    }

    &.left {
      float: left;
    }

    &.right {
      float: right;
    }

    &:disabled {
      opacity: 0;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .card-gallery-controls {
    .btn {
      width: 90px;
      
      .icon {
        @include rem('font-size', 12px);
      }
    }
  }
}