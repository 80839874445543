@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/components/typography';
@import '../../../styles/tools/mixins';

.footer-logo {
  float: left;
  position: relative;
  width: 50%;
  vertical-align: top;

  .link-item {
    align-items: center;
    display: inline-flex;
    justify-content: flex-start;
    flex-flow: row wrap;
    float: left;
    text-decoration: none;
    position: relative;
    vertical-align: top;

    .text {
      @include font($font-default, 14px, 400, $neutra-03);
      margin: 5px 0 0 35px;
      width: auto;
    }

    svg {
      @extend %logo;
      float: left;
      height: 35px;
      position: relative;
      width: auto;
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .footer-logo {
    padding: 0 0 40px;
    text-align: center;
    width: 100%;
    
    .link-item {
      display: inline-block;
      text-align: center;
      width: 100%;

      .text {
        @include rem('font-size', 14px);
        display: inline-block;
        margin: 0 auto;
        width: 100%;
      }

      svg {
        @include rem('font-size', 80px);
        float: none;
        margin: 0 0 30px;
      }
    }
  }
}