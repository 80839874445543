@import '../../styles/settings/colors';
@import '../../styles/settings/grid';

.footer {
  background-color: $neutra;
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.25);
  float: left;
  padding: 30px 20px;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  &--container {
    align-items: center;
    display: inline-flex;
    justify-content: space-between;
    max-width: $page-width;
    position: relative;
    text-align: left;
    width: 100%;
    vertical-align: top;
  }

  &--menu {
    float: right;
    max-width: 40%;
    text-align: right;
    width: 100%;
    vertical-align: top;
  }

  &[data-type="true"] {
    box-shadow: none;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .footer {
    &--container {
      display: inline-block;
    }

    &--menu {
      max-width: none;
    }
  }
}