@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.header {
  &-submenu {
    background-color: $neutra;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.12);
    display: none;
    left: 0;
    margin: 10px 0 0;
    overflow: hidden;
    max-width: 507px;
    position: absolute;
    top: 100%;
    width: 507px;
    z-index: 10;

    &--background,
    &--item,
    &--link,
    &--list {
      float: left;
      margin: 0;
      position: relative;
      text-align: left;
      width: 100%;
      vertical-align: top;
    }

    &--background {
      height: 100%;
      object-fit: cover;
      width: 218px;
    }

    &--item {
      border: none;
      border-bottom: 1px solid rgba($neutra-02, 0.3);
    }

    &--link {
      @include font($font-default, 14px, 400, $primary-color);
      line-height: 1em;
      opacity: 1;
      padding: 10px 0;
      text-decoration: none;

      .icon {
        @include rem('font-size', 12px);
        right: 15px;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
      }

      &.active,
      &:hover {
        color: $secondary-color;

        .icon {
          color: $secondary-color;
        }
      }
    }

    &--list {
      list-style: none;
      padding: 15px 0 15px 15px;
      width: calc(100% - 218px);
    }

    &:empty {
      display: none;
    }

    &[data-active="true"],
    &:hover {
      display: inline-block;
    }
  }

  &[data-media="sm"],
  &[data-media="xs"],
  &[data-media="md"] {
    .header {
      &-submenu {
        background-color: transparent;
        box-shadow: none;
        border-radius: 0;
        left: initial;
        margin: 5px 0 30px;
        max-width: initial;
        position: relative;
        top: initial;
        width: 100%;
  
        &--background {
          display: none;
        }
  
        &--item {
          border: none;
  
          .header-submenu--link {
            .icon {
              display: none;
            }
          }
  
          &:first-child {
            .header-submenu--link {
              padding-top: 2px;
            }
          }
  
          &:last-child {
            .header-submenu--link {
              padding-bottom: 2px;
            }
          }
        }
  
        &--link {
          @include rem('font-size', 16px);
          color: $neutra;
          padding: 15px;
        }
  
        &--list {
          padding: 0;
          width: 100%;
  
          &:before {
            background-color: $secondary-color;
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 2px;
          }
        }
      }
    }
  }
}