@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.footer-menu {
  margin: 0;
  position: relative;
  vertical-align: top;

  &--item {
    margin: 0;
    padding: 0 11px;
    position: relative;
    vertical-align: top;

    .link-item {
      @include font($font-default, 14px, 400, $primary-color);
      float: left;
      line-height: 1em;
      vertical-align: top;

      .text {
        margin: 0;
      }
    }

    &:before {
      background-color: $neutra-02;
      content: '';
      right: -1px;
      height: 100%;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: 1px;
    }

    &:last-child {
      padding-right: 0;

      &:before {
        display: none;
      }
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .footer-menu {
    padding: 20px 0 40px;
    text-align: center;
    width: 100%;
  }
}