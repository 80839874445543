@import '../../styles/settings/colors';
@import '../../styles/settings/grid';

.card {
  float: left;
  margin: 60px 0;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  &-container {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    flex-flow: row wrap;
    max-width: $page-width;
    padding: 20px 0;
    position: relative;
    width: 100%;
    vertical-align: top;
  }

  &[data-align="left"] {
    .card {
      &-info {
        order: 2;
      }

      &-image {
        order: 1;
      }
    }
  }

  &[data-align="right"] {
    .card {
      &-info {
        order: 1;
      }
      
      &-image {
        order: 2;
      }
    }
  }

  &[data-type="tipo_1"] {
    .card {
      &-image {
        &--background {
          svg {
            .line {
              fill: $neutra-05;
            }
          }
        }
      }
    }
  }

  &[data-type="tipo_2"] {
    .card {
      &-image {
        &--background {
          background-color: $secondary-dark;

          svg {
            .line {
              fill: $primary-light;
            }
          }
        }
      }
    }
  }

  &[data-type="tipo_3"] {
    .card {
      &-image {
        &--background {
          background-color: $neutra-05;

          svg {
            .line {
              fill: $primary-light;
            }
          }
        }
      }
    }
  }

  &[data-type="tipo_4"] {
    .card {
      &-image {
        &--background {
          background-color: $primary-light;
        }
      }
    }
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .card {
    &-container {
      display: inline-block;
      padding: 0;
      text-align: center;
    }

    &[data-align="left"],
    &[data-align="right"] {
      .card {
        &-image {
          float: none;
          z-index: 1;
        }

        &-info {
          z-index: 2;
        }
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .card {
    margin: 20px 0;
  }
}