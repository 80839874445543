@import '../../styles/components/animations';

.image {
  display: inline-block;
  opacity: 0;
  position: relative;
  transition: all $base-duration $base-ease;

  &[data-loading="true"] {
    opacity: 1;
  }
}