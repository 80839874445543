@import '../../../styles/settings/grid';

.card-collapse-image {
  float: left;
  position: relative;
  text-align: right;
  top: 0;
  width: 50%;
}

@media only screen and (max-width : $page-width) {
  .card-collapse-image {
    text-align: center;
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .card-collapse-image {
    float: none;
    margin: 0 auto;
    max-width: 480px;
    width: 100%;

    img {
      height: auto;
      width: 100%;
    }
  }
}