@import '../../../styles/settings/colors';

.list-indicators {
  background-color: $primary-light;
  float: left;
  margin: 0;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  .title-text {
    margin-top: 60px;
    
    .text {
      color: $neutra;
    }
  }
}