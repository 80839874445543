@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';
@import '../../../styles/settings/grid';

@import '../../../styles/components/icons';

@import '../../../styles/tools/mixins';

.features-slider {
  float: none;
  max-width: $container-width;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  .slick {
    &-list,
    &-track { 
      touch-action: pan-y;
    }

    &-list {
      float: left;
      padding: 0;
      overflow: hidden;
      position: relative;
      width: 100%;
    }

    &-slide {
      align-items: flex-start;
      display: inline-flex;
      justify-content: center;

      > div {
        align-items: flex-start;
        display: inline-flex;
        justify-content: center;
        float: left;
        position: relative;
        width: 100%;
      }
    }

    &-slider {
      float: left;
      position: relative;
      width: 100%;
    }

    &-track {
      float: left;
      position: relative;
      width: 100%;
      vertical-align: top;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .features-slider {
    .slick {
      &-track {
        display: inline-flex;
      }
    }
  }
}