@import '../../styles/settings/colors';
@import '../../styles/settings/grid';

.block-ship {
  float: left;
  margin: 60px 0;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;
  z-index: 10;

  &--container {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    flex-flow: row wrap;
    float: none;
    margin: 0 auto;
    max-width: $container-width;
    padding: 0;
    position: relative;
    width: 100%;
    vertical-align: top;
  }

  &[data-orientation="right"] {
    .block-ship-image {
      order: 2;
    }
    
    .block-ship-info {
      order: 1;
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .block-ship {
    margin: 60px 0;

    &-info {
      padding: 0 0 0 50px;
    }

    &--container {
      align-items: flex-start;
      padding: 0 90px;
    }

    &[data-orientation="right"] {
      .block-ship {
        &-info {
          padding: 0 50px 0 0;
        }
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .block-ship {
    &--container {
      padding: 0 30px 0 0;
    }

    &[data-orientation="right"] {
      .block-ship {
        &--container {
          padding: 0 20px 0 30px;
        }
      }
    }
  }
}

/* Custom, iPhone Retina */ 
@media only screen and (max-width : 480px) {
  .block-ship {
    &--container {
      display: inline-block;
      padding: 0 30px;
    }

    &[data-orientation="left"],
    &[data-orientation="right"] {
      .block-ship {
        &-info {
          padding: 0;
        }
      }
    }
  }
}