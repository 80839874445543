@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/components/forms/buttons';

@import '../../../styles/tools/mixins';

.timeline-controls-mobile {
  left: 0;
  position: fixed;
  top: 80px;
  width: 100vw;
  z-index: 1000;

  &--slider {
    float: left;
    position: relative;
    width: 100%;
    vertical-align: top;

    .btn-item {
      @extend %btn-clear;
      @include font($font-default, 16px, 700, $primary-dark);
      background-color: $neutra-05;
      margin: 0 10px;
      padding: 5px 20px;
      text-decoration: none;
    }
    
    .slick {
      &-active {
        .btn-item {
          background-color: $primary-dark;
          color: $neutra;
        }
      }

      &-list,
      &-track {
        touch-action:pan-y;
      }
      
      &-list {
        float: left;
        overflow: hidden;
        position: relative;
        width: 100%;
      }
  
      &-slide {
        align-items: flex-start;
        display: inline-flex;
        justify-content: center;
  
        > div {
          align-items: flex-start;
          display: inline-flex;
          justify-content: center;
          float: left;
          position: relative;
          width: 100%;
        }
      }
  
      &-track {
        float: left;
        position: relative;
        width: 100%;
        vertical-align: top;
      }
    }
  }
}