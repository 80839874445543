.social-media-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  float: left;
  position: relative;
  width: 100%;
  vertical-align: top;

  &--slider {
    float: left;
    position: relative;
    width: 100%;
    vertical-align: top;
    
    .slick {
      position: relative;

      &-list,
      &-track {
        touch-action:pan-y;
      }
      
      &-list {
        float: left;
        overflow: hidden;
        position: relative;
        width: 100%;
      }
  
      &-slide {
        align-items: flex-start;
        display: inline-flex;
        justify-content: center;
  
        > div {
          align-items: flex-start;
          display: inline-flex;
          justify-content: center;
          height: 100%;
          float: left;
          position: relative;
          width: 100%;
        }
      }
  
      &-track {
        display: inline-flex;
        flex-flow: row nowrap;
        float: left;
        position: relative;
        width: 100%;
        vertical-align: top;
      }
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .social-media-list {
    display: inline-block;
    
    &--slider {
      pointer-events: auto;
    }
  }
}