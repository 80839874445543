@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.list-address-item {
  float: left;
  margin: 30px 0;
  padding: 10px;
  position: relative;
  width: 100%;
  vertical-align: top;

  &--image,
  &--info {
    float: left;
    position: relative;
  }

  &--image {
    width: 205px;

    .image {
      border-radius: 5px;
      box-shadow: 0px 3px 20px rgba(25, 38, 57, 0.24);
      height: auto;
      float: left;
      width: 100%;
      vertical-align: top;
    }

    &:after {
      background-color: $secondary-color;
      content: '';
      height: 4px;
      left: -20px;
      position: absolute;
      top: 50%;
      width: 65px;
    }
  }

  &--info {
    padding: 0 40px;
    text-align: left;
    width: calc(100% - 205px);

    .description,
    .title {
      float: left;
      position: relative;
      width: 100%;
      vertical-align: top;
    }

    .btn-border {
      border-color: $neutra;
      color: $secondary-color;
      padding-left: 0;

      .icon {
        @include rem('font-size', 9px);
        margin-left: 20px;
      }
    }

    .description {
      @include font($font-default, 16px, 300, $neutra-04);
      margin: 0 0 30px;

      > p {
        line-height: 1.2em;
        margin: 8px 0;
        width: 100%;
        vertical-align: top;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    .title {
      @include font($font-default, 24px, 400, $primary-dark);
      line-height: 1.3em;
      margin: 10px 0 20px;
      text-transform: uppercase;
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .list-address-item {
    float: none;
    margin: 0;
    padding: 0 10px;
    width: 320px;

    &--image {
      padding-top: 20px;
      width: 100%;

      img {
        box-shadow: 0px 3px 10px rgba(25, 38, 57, 0.24);
        max-height: 360px;
        object-fit: cover;
      }
    }

    &--info {
      padding: 20px;
      width: 100%;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .list-address-item {
    width: 290px;
  }
}