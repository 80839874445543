@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/components/animations';
@import '../../../../styles/components/forms/buttons';

@import '../../../../styles/tools/mixins';

.gallery-modal-controls {
  animation: fade-top-in 0.4s 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  left: 50%;
  height: 20px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
  z-index: 2;

  .btn-item {
    @extend %btn-clear;
    outline: none;
    position: absolute;
    pointer-events: auto;

    .icon {
      @include rem('font-size', 15px);
      color: $neutra;
    }

    &.left {
      left: 50px;

      .icon {
        transform: rotate(90deg);
      }
    }

    &.right {
      right: 50px;

      .icon {
        transform: rotate(-90deg);
      }
    }
  }

  @keyframes fade-top-in {
    0% {
      opacity: 0;
      transform: translate(0%, 50%);
    }
    100% {
      opacity: 1;
      transform: translate(0%, 0);
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .gallery-modal-controls {
    .btn-item {
      &.left {
        left: 5px;
      }

      &.right {
        right: 5px;
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .gallery-modal-controls {
    bottom: 30px;
    top: initial;

    .btn-item {
      &.left {
        left: 50px;
      }

      &.right {
        right: 50px;
      }
    }
  }
}