@import '../../../styles/settings/colors';
@import '../../../styles/tools/mixins';

.background-scroll {
  height: 100%;
  left: 50%;
  min-height: 100%;
  opacity: 0.3;
  pointer-events: none;
  position: fixed;
  width: 100%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;

  > svg {
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    width: 100%;

    path {
      fill: $neutra-05;
    }
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .background-scroll {
    display: none;
  }
}