@import '../../styles/tools/mixins';

.image-full {
  @include parallax-fix;
  float: left;
  margin: 20px auto;
  position: relative;
  width: 100%;
  vertical-align: top;

  .image {
    height: auto;
    object-fit: cover;
    position: relative;
    max-width: 100%;
    margin: 0 auto;
    vertical-align: top;
    display: block;
  }
}