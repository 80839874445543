/* --- TYPOGRAPHY --- */
@import './../settings/colors';
@import './../settings/fonts';

@import './../components/animations';

@import './../tools/mixins';

%logo {
  .fill {
    &-st0,
    &-st1,
    &-st2 {
      transition: all 400ms $base-ease;
    }
    
    &-st0 {
      fill: $secondary-color;
    }
  
    &-st1 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: $primary-color;
    }
  
    &-st2 {
      fill: $primary-color;
    }
  }
}

%title-super {
  @include font($font-default, 40px, 600, $secondary-dark);
}

%title-main {
  @include font($font-default, 18px, 400, $primary-dark);
  padding: 0 0 10px;
  text-transform: uppercase;

  &:before {
    background-color: $secondary-color;
    content: '';
    left: 50%;
    height: 4px;
    position: absolute;
    top: 100%;
    transform: translate(-50%, 0);
    width: 37px;
  }
}

.title-h1 {
  @include font($font-default, 45px, 600, $neutra);
  line-height: 1em;

  &.ts {
    font-weight: 300;
  }

  &.st {
    font-weight: 800;
  }
}

.title-h4 {
  @include font($font-default, 32px, 700, $neutra);
  line-height: 1em;
}

.title-home {
  @include font($font-default, 60px, 400, $neutra);
  line-height: 1em;
}

.subtitle {
  @include font($font-default, 20px, 400, $neutra);
  line-height: 1.4em;

  p {
    margin: 0;
    vertical-align: top;
  }
}

.subtitle-min {
  @include font($font-default, 16px, 400, $neutra);
  line-height: 1.2em;
}

.link {
  @include font($font-default, 18px, 400, $neutra);
  line-height: 1em;
  padding: 15px 0;
  outline: none;
  text-decoration: none;

  &:before {
    background-color: $secondary-color;
    content: '';
    height: 4px;
    left: 0;
    position: absolute;
    transition: width 300ms $base-ease;
    top: calc(100% - 4px);
    width: 0;
  }

  &:hover,
  &.active {
    &:before {
      width: 48px;
    }
  }
}

@media only screen and (max-width: 576px) {
  h1 {
    @include rem('font-size', 24px);
  }
}