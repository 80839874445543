/* --- BUTTONS --- */
@import '../../settings/colors';
@import '../../settings/fonts';

@import '../animations';
@import '../../tools/mixins';

%btn-clear {
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
  pointer-events: auto;
  position: relative;
  transition: all 300ms $base-ease;
}

.btn-clear {
  @extend %btn-clear;
}

.btn-border {
  @extend %btn-clear;
  @include font($font-default, 14px, 800, $primary-color);
  align-items: center;
  border: 1px solid $primary-color;
  display: inline-flex;
  flex-flow: row wrap;
  height: 40px;
  justify-content: flex-start;
  line-height: 1em;
  padding: 6px 8px 5px;
  text-decoration: none;
  text-transform: uppercase;

  .icon {
    float: right;
    margin: 0 3px 0 10px;
    position: relative;
  }
}

.btn-circle {
  @extend %btn-clear;
  border-radius: 100%;
  border: 1px solid $neutra;
  box-shadow: 0px 0px 0px 1px $neutra;
  float: left;
  height: 17px;
  width: 17px;

  &:after {
    background-color: $secondary-color;
    border-radius: 100%;
    content: '';
    height: calc(100% - 4px);
    left: 2px;
    opacity: 0;
    position: absolute;
    transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: scale(0) translate(0, -0);
    transform-origin: center;
    top: 2px;
    width: calc(100% - 4px);
  }

  &[data-active="true"] {
    &:after {
      opacity: 1;
      transform: scale(1) translate(0, 0);
    }
  }
}

.btn-hight {
  @extend %btn-clear;
  background-color: rgba($black-900, 0.1);
  height: 100%;
  position: relative;
  pointer-events: auto;
  width: 168px;

  .icon {
    @include rem('font-size', 23px);
    color: $neutra;
    position: relative;
    top: 20px;
  }

  &.left {
    float: left;

    .icon {
      left: 10px;
    }
  }

  &.right {
    float: right;

    .icon {
      right: 10px;
    }
  }
}

@media only screen and (max-width: 576px) {
  .btn-circle {
    height: 16px;
    width: 16px;
  }
}