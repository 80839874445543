@import '../../../../styles/settings/colors';

.timeline-home-background {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;

  svg {
    right: 0;
    opacity: 0.2;
    position: absolute;
    top: 0;
    transform-origin: top left;

    path {
      fill: $neutra-05;
    }
  }
}