@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.list-indicators-item {
  float: left;
  padding: 60px 80px;
  position: relative;
  width: 100%;
  vertical-align: top;

  .description,
  .title-number {
    float: left;
    margin: 0;
    position: relative;
    width: 100%;
    vertical-align: top;
  }

  .description {
    @include font($font-default, 35px, 800, $secondary-color);
    line-height: 1.3em;
    text-transform: uppercase;
  }

  .title-number {
    @include font($font-default, 60px, 400, $neutra);
    list-style: 1em;
    margin: 0 0 10px;
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .list-indicators-item {
    padding: 60px 20px;
    width: 100%;

    .description {
      font-weight: 400; 
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .list-indicators-item {
    .description {
      @include rem('font-size', 18px);
    }

    .title-number {
      @include rem('font-size', 48px);
    }
  }
}