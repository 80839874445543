@import '../../styles/settings/grid';

.header {
  left: 50%;
  max-width: $page-width;
  position: absolute;
  text-align: center;
  top: 0;
  transform: translate(-50%, 0);
  z-index: 999;
  width: 100%;

  &[data-media="sm"],
  &[data-media="xs"],
  &[data-media="md"] {
    position: fixed;

    &[data-active="true"] {
      height: 100vh;
    }
  }
}