@import '../../../../styles/settings/grid';

@import '../../../../styles/components/animations';

.timeline-home {
  &-image {
    float: left;
    height: 100%;
    position: relative;
    text-align: center;
    width: 45%;
    vertical-align: top;

    .image {
      left: calc(50% + 60px);
      opacity: 1;
      position: absolute;
      top: -45%;
      pointer-events: none;
      transition: all 900ms $base-ease;
      transition-delay: 1000ms;
      transform: translate(-50%, 0);
      transform-origin: left top;
    }
  }

  &[data-active="false"] {
    .timeline-home {
      &-image {
        .image {
          left: calc(50% - 60px);
          transform: scale(0.6) translate(-50%, 0);
          transition-delay: 0s;
          top: 50px;
        }
      }
    }
  }
}

/* Large Devices, Wide Screens */
@media only screen and (max-width : $page-width) {
  .timeline-home {
    &[data-active="false"] {
      .timeline-home {
        &-image {
          .image {
            left: calc(50% + 60px);
            transform: scale(0.4) translate(-50%, 0);
            transition-delay: 0s;
            top: 50px;
          }
        }
      }
    }
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .timeline-home {
    &[data-active="true"] {
      .timeline-home {
        &-image {
          .image {
            transform: scale(0.7) translate(-50%, 0);
          }
        }
      }
    }

    &[data-active="false"] {
      .timeline-home {
        &-image {
          .image {
            left: calc(50% + 20px);
            transform: scale(0.4) translate(-50%, 0);
            transition-delay: 0s;
            top: 50px;
          }
        }
      }
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .timeline-home {
    &[data-active] {
      .timeline-home {
        &-image {
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;

          .image {
            left: 50%;
            transform: translate(-50%, -50%);
            top: 0;
            width: 170px;
          }
        }
      }
    }
  }
}