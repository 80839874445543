.table {
  float: left;
  margin: 30px 0 30px 0;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;
  font-family: "TheSans";
  overflow-x: auto;
  padding-bottom: 10px;

  .table-row {
    display: block;
    border-bottom: 1px solid #475C7A;
    border-left: 1px solid #475C7A;
    border-right: 1px solid #475C7A;
  
    &:first-child {
      border: 1px solid #475C7A;
    }
  
    &:nth-child(2n + 2) {
      background: #E9EBEE;
    }

    th,
    td {
      min-width: 250px;
      max-width: 250px;
      padding: 10px;
      display: inline-flex;
      justify-content: center;
      flex-wrap: wrap;

      a {
        color: #EF7D05;
        text-decoration: underline;
      }
    }

    th {
      background: #475c7a;
      color: #ffffff;
      font-weight: 700;
      font-size: 18px;
      text-transform: uppercase;
    }

    td {
      color: #263955;
      font-weight: 700;
      font-size: 16px;
    }
  }
}

@media (min-width: 375px) and (max-width: 1249px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;

    .table-row {
      display: flex;
    }

    &::-webkit-scrollbar {
      height: 3px;
    }
  
    &::-webkit-scrollbar-track {
      background: #c3c3c3;
    }
    
    &::-webkit-scrollbar-thumb {
      background: #EF7D05;
      border-radius: 20px;
    }
  }
}
