.gallery-slider-item {
  display: none;
  left: 0;
  height: calc(100% - 140px);
  position: absolute;
  top: 80px;
  width: 100%;
  will-change: transform;

  img {
    float: left;
    height: 100%;
    object-fit: cover;
    position: relative;
    pointer-events: none;
    width: 100%;
  }

  &[data-active="true"],
  &[data-last="true"] {
    align-items: center;
    justify-content: center;
    display: inline-flex;
  }

  &[data-active="true"] {
    z-index: 3;
  }

  &[data-last="true"] {
    z-index: 1;
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 768px) {
  .gallery-slider-item {
    height: auto;
    top: 50%;
    transform: translate(0px, -50%) !important;
  }
}