@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';
@import '../../styles/settings/grid';

@import '../../styles/tools/mixins';

.block-map {
  background-color: $neutra-05;
  float: left;
  margin: 60px 0;
  overflow: hidden;
  padding: 60px 0 0;
  text-align: center;
  position: relative;
  width: 100%;
  vertical-align: top;

  &--content {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    padding-bottom: 50px;
  }

  &--container {
    float: none;
    max-width: $container-width;
    width: 50%;
    vertical-align: top;
  }

  &--info {
    float: left;
    padding: 60px;
    text-align: left;
    max-width: 610px;
    vertical-align: top;

    .description,
    .title {
      float: left;
      line-height: 1.4em;
      position: relative;
      width: 100%;
      vertical-align: top;
    }

    .description {
      @include font($font-default, 16px, 400, $neutra-03);

      > p {
        margin-top: 0;
      }
    }

    .title {
      @include font($font-default, 40px, 600, $primary-light);
      margin: 0 0 40px;
      line-height: 1em;
    }
  }

  &--image {
    width: 50%;

    .image {
      float: left;
      height: 100%;
      width: auto;
      max-width: 100%;
      vertical-align: top;
      object-fit: contain;

      &.mobile {
        display: none;
      }

      @media screen and (max-width: 768px) {
        &.desktop{
          display: none;
        }

        &.mobile {
          display: inline-block;
        }
      }
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .block-map {
    &--content {
      flex-direction: column;
    }
    &--image,
    &--container {
      width: 100%;
    }
    &--info {
      padding: 60px 140px;
      width: 100%;
      max-width: 100%;
    }

    &--image {
      padding: 0 90px;      
      width: 100%;

      .image {
        height: auto;
        width: 100%;
      }
    }
    
    .title-text {
      margin: 10px 0;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .block-map {
    &--info {
      padding: 60px;
    }

    &--image {
      padding: 0 20px;
    }
  }
}

/* Custom, iPhone Retina */ 
@media only screen and (max-width : 480px) {
  .block-map {
    &--info {
      padding: 60px 30px;
    }

    &--image {
      padding: 0;
    }
  }
}
