@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';
@import '../../../styles/settings/grid';

@import '../../../styles/components/icons';

@import '../../../styles/tools/mixins';

.gallery-thumbs {
  float: none;
  margin: 30px 0 60px;
  max-width: $page-width;
  padding: 0 20px;
  position: relative;
  width: 100%;
  vertical-align: top;

  &--content {
    left: 0;
    height: auto;
    padding: 0;
    position: relative;
    top: 0;
    width: 100%;

    .slick {
      &-list,
      &-track {
        touch-action: pan-y;
      }
      
      &-list {
        float: left;
        overflow: hidden;
        position: relative;
        width: 100%;
      }

      &-slide {
        align-items: flex-start;
        display: inline-flex;
        justify-content: center;

        > div {
          align-items: flex-start;
          display: inline-flex;
          justify-content: center;
          height: 100%;
          float: left;
          position: relative;
          width: 100%;
        }
      }

      &-track {
        display: inline-flex;
        flex-flow: row nowrap;
        float: left;
        position: relative;
        width: 100%;
        vertical-align: top;
      }
    }
  }
}

 /* Medium Devices, Desktops */
 @media only screen and (max-width : 1024px) {
  .gallery-thumbs {
    padding: 0;

    &--content {
      padding-top: 20px;
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .gallery-thumbs {
    margin-top: 0;

    &--content {
      padding-top: 0px;
    }
  }
}