@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/components/animations';

@import '../../../../styles/tools/mixins';

.input-control {
  float: left;
  margin: 10px 0;
  position: relative;

  .info,
  .label {
    @include font($font-default, 14px, 400, $neutra-03);
    left: 0;
    line-height: 1em;
    padding: 0 15px;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transition: all 400ms $base-ease;
    transform: translate(0, -50%);
    width: 100%;
  }

  .info {
    color: $black-900;
    font-weight: 600;
    left: initial;
    right: 0;
    padding-right: 0;
    transform: initial;
    top: 4px;
    width: auto;
  }

  .error {
    @include font($font-default, 14px, 600, $secondary-dark);
    height: 0;
    float: left;
    padding: 0;
    overflow: hidden;
    padding: 0 15px;
    transition: all 400ms $base-ease;
    width: 100%;
    vertical-align: top;
  }

  input {
    &[type="text"],
    &[type="email"] {
      @include font($font-default, 18px, 400, $neutra-03);
      border: 1px solid $neutra-03;
      float: left;
      line-height: 1em;
      padding: 14px 15px;
      width: 100%;
      vertical-align: top;

      &:focus,
      &:active {
        padding: 21px 15px 7px;

        & + .label {
          color: $primary-color;
          font-weight: 600;
          top: 7px;
          transform: translate(0, 0);
        }
      }
    }
  }

  textarea {
    @include font($font-default, 18px, 400, $neutra-03);
    border: 1px solid $neutra-03;
    resize: none;
    padding: 15px;
    width: 100%;
  }

  &[data-type="checkbox"] {

    &[data-active="true"] .label {
      color: #263955;
    }
    .label {
      position: relative;
      display: inline;
    }
  }

  &[data-active="true"],
  &[data-error="true"] {
    .label {
      font-weight: 600;
      top: 15px;
    }

    input {
      padding: 21px 15px 7px;
    }
  }

  &[data-error="true"] {
    .error {
      height: auto;
      margin: 0;
      padding: 5px 15px;
    }

    .label {
      color: $secondary-dark;
    }

    input,
    textarea {
      border: 2px solid $secondary-dark;
    }
  }

  &[data-active="true"] {
    margin-bottom: 8px;

    .label {
      color: $primary-color;
    }

    input {
      border: 2px solid $primary-color;
    }
  }

  &[data-error="true"][data-active="true"] {
    input {
      border-color: $secondary-dark;

      & + .label {
        color: $secondary-dark;
      }
    }
  }

  &[data-type="textarea"] {
    padding: 30px 0 0;

    .label {
      padding: 0;
      top: 10px;
    }

    &[data-active="true"] {
      textarea {
        border: 2px solid $primary-color;
      }
    }
  }
}