.hxmpXe {
  flex-wrap: wrap;
  flex-direction: column;
}

.ht-skip #menuDescription,
#containerDescription,
#contrastDescription,
#opacityDescription,
#positionDescription,
#researchDescription, 
#menuDescriptionSettings,
#menuDescriptionInfo {
  display: none;
}

