@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.blog-item {
  background-color: $primary-light;
  float: left;
  height: 100%;
  position: relative;
  width: 100%;
  vertical-align: top;

  &--wrapper {
    width: 32%;
    margin-right: 2%;
    margin-bottom: 2%;
    @media only screen and (min-width : 1024px) {
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    @media only screen and (max-width : 1023px) {
      width: 49%;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
    @media only screen and (max-width : 480px) {
      width: 100%;
      margin: 0;
    }
  }

  &--image,
  &--info {
    float: left;
    position: relative;
    width: 100%;
    vertical-align: top;
  }

  &--image {
    .image {
      float: left;
      height: auto;
      position: relative;
      width: 100%;
      vertical-align: top;
      user-select: none;
    }

    .taxonomy {
      @include font($font-default, 20px, 400, $neutra-04);
      background-color: $neutra-05;
      bottom: 0;
      left: 0;
      line-height: 1em;
      margin: 0;
      padding: 10px 15px;
      position: absolute;
    }
  }

  &--info {
    background-color: $primary-light;
    padding: 35px 30px;

    .description,
    .title {
      @include font($font-default, 16px, 300, $neutra);
      float: left;
      line-height: 1.4em;
      position: relative;
      width: 100%;
      vertical-align: top;
    }

    .description {
      line-height: 1.6em;

      > p {
        margin: 0 0 10px;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    .title {
      @include font($font-default, 18px, 300, $neutra);
      margin: 0 0 20px;
      text-transform: uppercase;
    }
  }
}