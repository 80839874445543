@import '../../styles/settings/settings';

.alert--footer {
    font-family: $font-default;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    justify-items: center;
    background-color: #fff;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    float: left;
    padding: 22px 0;
    position: relative;
    width: 100%
}

.alert--footer.fixed-bottom {
    position: fixed;
    bottom: 0;
    z-index: 1000;
    left: 0
}

.alert--footer .wrapper {
    box-sizing: border-box;
    display: inline-block;
    max-width: 1140px;
    text-align: left;
    width: 100%;
    vertical-align: top;
    margin: 0 auto
}

.alert--footer .alert--actions,
.alert--footer .alert--content,
.alert--footer .alert--info {
    box-sizing: border-box;
    display: flex;
    float: left;
    position: relative;
    align-items: center;
}

.alert--footer .alert--content {
    -ms-flex-line-pack: center;
    align-content: center;
    display: inline-flex;
    padding: 0 15px;
    width: 100%;
}

.alert--footer .alert--info {
    width: 90%;
    padding-right: 20px
}

.alert--footer .alert--info .info,.alert--footer .alert--info .info-icon {
    box-sizing: border-box;
    display: inline-block;
    float: left;
    margin: 0;
    position: relative
}

.alert--footer .alert--info svg {
    width: 46px
}

.alert--footer .alert--info svg path {
    fill: $secondary-color
}

.alert--footer .alert--info .info-icon .home--news--list .slick-arrow,.alert--footer .alert--info .info-icon .icon,.home--news--list .alert--footer .alert--info .info-icon .slick-arrow {
    font-size: 46px;
    font-size: 2.875rem;
    color: $secondary-color
}

.alert--footer .alert--info .info-icon .home--news--list .slick-arrow svg path,.alert--footer .alert--info .info-icon .icon svg path,.home--news--list .alert--footer .alert--info .info-icon .slick-arrow svg path {
    fill: $secondary-color
}

.alert--footer .alert--info .info {
    padding: 0 13px;
    width: calc(100% - 45px)
}

.alert--footer .alert--info .info .description {
    box-sizing: border-box;
    display: inline-block;
    float: left;
    position: relative;
    font-size: 15px;
    font-size: .9375rem;
    color: #494d52;
    font-weight: 400;
    line-height: 1.4em;
    margin: 0 0 5px
}

.alert--footer .alert--info .info .link {
    font-weight: 700;
    color: $secondary-color;
    text-decoration: none;
    line-height: 1em;
    font-size: 1em;
    display: inline;
    padding: 0;
    vertical-align: initial;
}

.alert--footer .alert--info .info-icon {
    height: 45px;
    width: 45px
}

.alert--footer .alert--actions {
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: inline-flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    justify-items: center
}

.alert--footer .alert--actions .action {
    background: $secondary-color;
    outline: none;
    color: #fff;
    border: 2px solid $secondary-color;
    -webkit-transition: background .4s ease,color .4s ease;
    transition: background .4s ease,color .4s ease;
    display: flex;
    border-radius: 100px;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 40px;
    line-height: 1.2em;
    font-size: 14px;
    text-align: center;
    padding: 0 22px;
    cursor: pointer;
    font-weight: 700
}

.alert--footer .alert--actions .action:hover {
    background: #fff;
    color: $secondary-color
}

@media only screen and (max-width: 640px) {
    .alert--footer .alert--content {
        display:inline-block
    }

    .alert--footer .alert--actions,.alert--footer .alert--info {
        width: 100%
    }

    .alert--footer .alert--actions {
        display: inline-block;
        padding: 15px 0 0 30px;
        text-align: left
    }

    .alert--footer .alert--actions>button.action {
        font-size: 12px;
        font-size: .75rem;
        vertical-align: top
    }

    .alert--footer .alert--actions .label-control {
        padding: 9px 0 0;
        vertical-align: top
    }

    .alert--footer .alert--actions .label-control .input-control.checkbox+label {
        font-size: 13px;
        font-size: .8125rem;
        padding-top: 2px
    }

    .alert--footer .alert--info svg {
        width: 100%
    }

    .alert--footer .alert--info .info {
        padding-right: 0;
        width: calc(100% - 19px)
    }

    .alert--footer .alert--info .info-icon {
        height: 19px;
        width: 19px
    }

    .alert--footer .alert--info .info-icon .home--news--list .slick-arrow,.alert--footer .alert--info .info-icon .icon,.home--news--list .alert--footer .alert--info .info-icon .slick-arrow {
        font-size: 28px;
        font-size: 1.75rem
    }
}