@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.header-top-mobile {
  background-color: $neutra;
  margin: 25px 0 0;
  padding: 15px 30px;
  position: relative;
  width: 100%;
  vertical-align: top;

  &--background {
    left: 0;
    bottom: 0;
    height: 205px;
    position: absolute;
    width: 100%;

    svg {
      opacity: 0.5;
      
      path {
        fill: $neutra-05;
      }
    }
  }

  &--item,
  &--list {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    vertical-align: top;
  }

  &--item {
    .link-item {
      @include font($font-default, 16px, 400, $primary-color);
      float: left;
      padding: 15px 0;
      position: relative;
      text-align: center;
      text-decoration: none;
      width: 100%;
    }
  }

  .footer-social {
    margin: 30px 0;
    text-align: center;
    width: 100%;
  }

  &:before {
    background-color: $neutra;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}