.select-lang {
  &-list {
    border-radius: 5px;
    box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.12);
    display: none;
    margin: 0;
    right: 0;
    padding: 0;
    position: absolute;
    top: calc(100% + 5px);
    width: 240px;
    background: #efefef;

    &--item {
      float: left;
      position: relative;
      width: 100%;
      vertical-align: top;

      &[data-active="true"] {
        display: none;
      }
    }

    @media screen and (max-width: 570px) {
      transform: translateX(50%);
      right: 50%;
    }
  }

  &[data-active="true"] {
    .select-lang {
      &-list {
        display: inline-block;
      }
    }
  }
}