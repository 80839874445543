.ships-tabs {
  float: left;
  padding: 20px 0;
  position: relative;
  width: 100%;
  vertical-align: top;

  &:after {
    border: none;
    border-bottom: 1px solid #000;
    bottom: 0;
    content: '';
    height: 0;
    left: 0;
    opacity: 0.2;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 768px) {
  .ships-tabs {
    padding: 20px 0 0;

    &:after {
      bottom: 10px;
    }
  }
}