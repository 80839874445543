.block-image {
  margin: 0 0 60px;
  position: relative;
  width: 100%;
  vertical-align: top;

  &[data-type="multiple"] {
    .block-image {
      &-item {
        width: 50%;
      }
    }
  }
}