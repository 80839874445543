@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.form-message {
  align-items: center;
  background-color: rgba($neutra, 0.9);
  display: inline-flex;
  flex-flow: row wrap;
  left: 0;
  justify-content: center;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100vw;
  vertical-align: top;
  z-index: 99999;

  &--content {
    position: relative;
    max-width: 650px;
    width: 100%;
  }

  &--description,
  &--title {
    float: left;
    position: relative;
    width: 100%;
    vertical-align: top;
  }

  &--description {
    @include font($font-default, 16px, 400, $neutra-06);
    margin: 30px 0;
    line-height: 1.6em;
  }

  &--title {
    margin: 0;
    padding: 60px 0 0;

    .icon {
      display: inline-block;
      color: $secondary-dark;
      left: 50%;
      position: absolute;
      transform: translate(-50%, 0);
      top: -80px;

      &.icon-close {
        @include rem('font-size', 44px);
      }

      &.icon-checked {
        @include rem('font-size', 44px);
        color: $primary-color;
      }
    }

    .title {
      @include font($font-default, 40px, 600, $primary-color);
      margin: 0;
    }
  }

  &--footer {
    float: left;
    position: relative;
    width: 100%;
    vertical-align: top;
  }

  &[data-type="error"] {
    .form-message {
      &--title {
        .title {
          color: $secondary-dark;
        }
      }
    }
  }
}