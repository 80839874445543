@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/components/forms/buttons';
@import '../../../styles/tools/mixins';

.tabs-header {
  padding: 0 20px;
  pointer-events: none;
  text-align: center;
  width: 100%;
  vertical-align: top;

  &--item,
  &--list {
    position: relative;
    vertical-align: top;
  }

  &--item {
    margin: 0 2px;

    .btn {
      @extend %btn-clear;
      @include font($font-default, 16px, 800, $primary-dark);
      background-color: $neutra-05;
      line-height: 1em;
      padding: 8px 0 7px;
      position: relative;
      pointer-events: auto;
      width: 205px;

      &[data-active="true"] {
        background-color: $primary-dark;
        color: $neutra;
        font-weight: 400;
      }
    }
  }

  &--list {
    border: none;
    border-bottom: 1px solid $neutra-02;
    margin: 0;
    padding: 0 0 1px;
    width: 100%;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .tabs-header {
    padding: 0;

    &--item {
      float: left;
      margin: 0 2px;
      width: calc((100% / 4) - 4px);

      .btn {
        width: 100%;
      }
    }

    &--list {
      padding: 0 20px;
    }
  }
}