@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.block-image-item {
  float: left;
  overflow: hidden;
  position: relative;
  width: 100%;
  vertical-align: top;

  &--info,
  &--image {
    float: left;
    position: relative;
    vertical-align: top;
  }

  &--info {
    bottom: 40px;
    left: 50px;
    max-width: calc(100% - 140px);
    position: absolute;
    width: 100%;

    .link-item,
    .subtitle,
    .title-h4 {
      float: left;
      position: relative;
      width: 100%;
      vertical-align: top;
    }

    .link-item {
      .text,
      .icon {
        color: $secondary-color;
        float: left;
        position: relative;
      }

      .icon {
        @include rem('font-size', 13px);
      }

      .text {
        @include font($font-default, 14px, 800, $secondary-color);
        margin: 10px 0;
        text-align: left;
        text-transform: uppercase;
      }
    }

    .subtitle,
    .title-h4 {
      margin: 0 0 10px;
    }

    .title-h4 {
      text-transform: uppercase;
    }
  }

  &--image {
    @include parallax-fix;
    height: 542px;
    width: 100%;

    .parallax-outer,
    .parallax-inner {
      height: 100%;
    }
    
    img {
      height: 100%;
      float: left;
      object-fit: cover;
      position: relative;
      transform: scale(1.1);
      width: 100%;
      vertical-align: top;
    }
  }

  &[data-color="azul"] {
    .block-image-item {
      &--info {
        z-index: 3;
        
        p {
          color: $primary-dark;
        }
      }

      &--image {
        &:before {
          background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 73.96%);
          bottom: 0;
          content: '';
          height: calc(100% - 115px);
          left: 0;
          opacity: 0.8;
          position: absolute;
          width: 100%;
          z-index: 2;
        }
      }
    }
  }

  &[data-type="true"] {
    width: 50%;
  }

  &[data-type="false"] {
    overflow: hidden;
    
    .block-image-item {
      &--info {
        align-content: flex-s;

        display: inline-flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: 1084px;
        padding: 0 30px;
        

        .title-home,
        .subtitle {
          text-align: center;
          position: relative;
          z-index: 2;
        }
        .subtitle {
          max-width: 830px;
          width: 100%;
        }
        .title-home {
          margin: 0 0 10px;
        }

        &:before {
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.29) 0%, rgba(0, 0, 0, 0.6) 100%);
          content: '';
          height: 250px;
          left: 50%;
          position: absolute;
          transform: translate(-50%, -50%);
          top: 50%;
          width: 100vw;
        }
      }
    }
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .block-image-item {
    &[data-type="true"] {
      width: 100%;
    }

    &[data-type="false"] {
      .block-image-item {
        &--info {
          display: inline-block;
          left: 0;
          padding: 0 30px;
          max-width: none;
          transform: translate(0, 0);
        }
      }
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .block-image-item {

    &--info {
      left: initial;
      max-width: none;
      padding: 190px 40px 0;
      position: relative;
    }

    &--image {
      left: 0;
      height: 100%;
      position: absolute;
      top: 0;
    }

    &[data-type="false"] {
      .block-image-item {
        &--info {
          display: inline-block;
          left: 0;
          padding: 0 30px;
          max-width: none;
          position: absolute;
          
          .title-home,
          .subtitle {
            text-align: left;
          }

          .subtitle {
            max-width: none;
            width: 100%;
          }

          .subtitle {
            // @include rem('font-size', 16px);
            font-size: 18px;

            span {
              font-size: inherit;
            }
          }
    
          .title-home {
            // @include rem('font-size', 22px);
            font-weight: 700;
            font-size: 25px;
          }
        }

        &--image {
          left: 0;
          height: 385px;
          position: relative;
          top: 0;
        }
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .block-image-item {
    &--info {
      padding: 40px 30px;
      bottom: unset;
      top: initial;
    }

    &[data-type="false"] {
      .block-image-item {
        &--image {
          .parallax-inner {
            transform: translate(0, 0) !important;
          }
        }

        &--info {
          top: 60%;

          .subtitle {
            // @include rem('font-size', 16px);
            font-size: 0.85em;
            top: -20px;
          }

          .title-home {
            // @include rem('font-size', 22px);
            font-size: 22px;
            top: -20px;
          }

          &:before {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.29) 0%, rgba(0, 0, 0, 0.6) 100%);
            content: '';
            height: 300px;
            left: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            width: 100vw;
          }
        }
      }
    }

    &[data-color="azul"] {
      .block-image-item {
        &--info {
          &:before {
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 50.96%);
            bottom: 0;
            content: '';
            height: calc(100% + 60px);
            left: 0;
            position: absolute;
            width: 100%;
            z-index: -1;
          }
        }

        &--image {
          &:before {
            display: none;
          }
        }
      }
    }
  }
}