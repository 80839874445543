@import '../../../../styles/settings/grid';

.ships-gallery-list {
  float: none;
  margin: 0 auto;
  max-width: calc(#{$page-width} - (140px * 2));
  list-style: none;
  padding: 0;
  position: relative;
  text-align: left;
  width: 100%;
  vertical-align: top;

  &--gallery {
    float: left;
    padding: 0;
    position: relative;
    width: 100%;
    vertical-align: top;

    .slick {
      &-list,
      &-track { 
        touch-action: pan-y;
      }

      &-list {
        float: left;
        padding: 0;
        overflow: hidden;
        position: relative;
        width: 100%;
      }

      &-slide {
        align-items: flex-start;
        display: inline-flex;
        justify-content: center;

        > div {
          align-items: flex-start;
          display: inline-flex;
          justify-content: center;
          float: left;
          position: relative;
          width: 100%;
        }
      }

      &-slider {
        float: left;
        position: relative;
        width: 100%;
      }

      &-track {
        float: left;
        position: relative;
        width: 100%;
        vertical-align: top;
      }
    }

    .ships-card {
      padding: 10px 10px 60px;
      width: 335px;
    }
  }

  &--item {
    padding: 15px;
    width: calc(100% / 3);
  }

  &[data-type="tipo_1"] {
    .ships-gallery-list {
      &--item {
        .ships-card {
          .title {
            color: $primary-color;
          }
        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          .ships-card {
            .title {
              color: $neutra;
            }
          }
        }
      }
    }
  }

  &[data-type="tipo_2"] {
    .ships-gallery-list {
      &--item {
        .ships-card {
          .title {
            color: $secondary-color;
          }
        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          .ships-card {
            .title {
              color: $neutra;
            }
          }
        }
      }
    }
  }

  &[data-type="tipo_3"] {
    .ships-gallery-list {
      &--item {
        .ships-card {
          .title {
            color: $neutra-05;
          }
        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          .ships-card {
            .title {
              color: $neutra;
            }
          }
        }
      }
    }
  }

  &[data-type="tipo_4"] {
    .ships-gallery-list {
      &--item {
        .ships-card {
          .title {
            color: $secondary-dark;
          }
        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          .ships-card {
            .title {
              color: $neutra;
            }
          }
        }
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 768px) {
  .ships-gallery-list {
    &--gallery {
      .slick-list {
        padding: 0 20px;
      }
    }
  }
}