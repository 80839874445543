.ships-filters-category-item {
  margin: 0 10px;
  position: relative;
  vertical-align: top;
  width: 20%;
  max-width: 200px;

  .select-min-title {
    width: 100%;
  }

  @media only screen and (max-width : 900px) {
    width: 48%;
    margin: 1%;
    max-width: none;
  }
  @media only screen and (max-width : 580px) {
    width: 100%;
    margin: 0 0 10px;
    max-width: none;
  }
}