/* --- COLORS --- */

$black-900: #222;

$neutra: #FFF;
$neutra-02: #CECECE;
$neutra-03: #464646;
$neutra-04: #192639;
$neutra-05: #B9D2DB;
$neutra-06: #505050;
$neutra-07: #979797;
$neutra-08: #EDEDED;

$primary-color: #0057A6;
$primary-dark: #263955;
$primary-light: #475C7A;

$secondary-color: #EF7D05;
$secondary-dark: #C14E2A;
$secondary-dark-100: #B14A29;
$secondary-dark-200: #DD5D00;
$secondary-dark-300: #DA623D;
$secondary-light-100: #FF8100;
