@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/components/icons';

@import '../../../styles/tools/mixins';

.features-history-slider {
  float: left;
  position: relative;
  width: 100%;
  vertical-align: top;

  &--content {
    left: 168px;
    height: auto;
    padding: 105px 0 0;
    position: relative;
    top: 0;
    width: calc(100% - (168px * 2));

    .slick {
      &-list,
      &-track {
        touch-action:pan-y;
      }
      
      &-list {
        float: left;
        overflow: hidden;
        position: relative;
        width: 100%;
      }

      &-slide {
        align-items: flex-start;
        display: inline-flex;
        justify-content: center;

        > div {
          align-items: flex-start;
          display: inline-flex;
          justify-content: center;
          height: 100%;
          float: left;
          position: relative;
          width: 100%;
        }
      }

      &-track {
        display: inline-flex;
        flex-flow: row nowrap;
        float: left;
        position: relative;
        width: 100%;
        vertical-align: top;
      }
    }
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .features-history-slider {
    &--content {
      left: 0;
      width: 100%;
    }
  }
}
