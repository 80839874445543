@import '../../../styles/settings/colors';
@import '../../../styles/components/animations';

.webdoor-info {
  animation: fade-in-fwd 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  left: 168px;
  padding: 0 30px;
  max-width: 410px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
  z-index: 5;

  .btn-border {
    border-color: $neutra;
    color: $neutra;
    margin: 40px 0 0;
    pointer-events: auto;
  }

  .title-h1 {
    margin: 0 10px 0 0;
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .webdoor-info {
    left: 55px;
    padding: 20px;
  }
}

@media only screen and (max-width: 640px) {
  .webdoor-info {
    animation: fade-in-fwd-mb 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    bottom: initial;
    left: 60px;
    max-width: none;
    height: auto;
    padding: 0;
    top: -50%;
    transform: translate(0, 50%);
    width: calc(100% - 68px);
  }
}

/* Custom, iPhone Retina */ 
@media only screen and (max-width : 480px) {
  .webdoor-info {
    left: 40px;
    width: calc(100% - 80px);
  }
}