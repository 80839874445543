@import '../../styles/settings/grid';

.video-player {
  float: left;
  margin: 40px 0;
  padding: 0;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  &--container {
    filter: drop-shadow(16px 16px 32px rgba(0, 0, 0, 0.15));
    float: none;
    max-width: calc(#{$page-width} - 280px);
    left: initial;
    height: auto;
    margin: 0 auto;
    padding: 0;
    position: relative;
    width: 100%;
    text-align: left;

    .video {
      left: 0;
      position: relative;
      top: 0;
      width: 100% !important;
      z-index: -1;

      > div {
        float: left;
        width: 100%;
        vertical-align: top;
      }
    }
  }
}