@import '../../../styles/settings/fonts';
@import '../../../styles/settings/grid';

@import '../../../styles/components/forms/buttons';
@import '../../../styles/components/animations';

@import '../../../styles/tools/mixins';

.header {
  &-item {
    float: left;
    margin: 0;
    padding: 0 70px 0 0;
    position: relative;
    text-align: left;
    vertical-align: top;

    .link {
      @include rem('font-size', 20px);
      padding: 15px 0 15px;
      width: 100%;
      vertical-align: top;

      &:after {
        background-color: transparent;
        content: '';
        height: 15px;
        left: 0;
        top: 100%;
        position: absolute;
        width: 100%;
        z-index: 2;
      }

      &:hover {
        & + .header-submenu {
          animation: slide-top 300ms ease both;
          display: inline-block;
        }
      }
    }

    .link-toggle {
      @extend %btn-clear;
      display: none;
      right: 0;
      outline: none;
      position: absolute;
      top: 20px;
      
      .icon {
        @include rem('font-size', 16px);
        color: $neutra;
      }

      &[data-active="true"] {
        .icon {
          transform: rotate(90deg);
        }
      }
    }

    &:nth-last-child(2) {
      .header-submenu {
        left: initial;
        right: -30%;
      }
    }

    &:last-child {
      padding-right: 0;
      
      .link {
        padding: 15px 0;
      }

      .header-submenu {
        left: initial;
        right: 0;
      }
    }

    &:hover {
      .link {
        &:before {
          width: 45px;
        }
      }
    }
  }

  &[data-media="xs"],
  &[data-media="sm"],
  &[data-media="md"] {
    .header {
      &-item {
        background-color: $primary-light;
        padding: 5px 30px;
        width: 100%;
  
        .link {
          &:after,
          &:before {
            display: none;
          }
        }
  
        .link-toggle {
          display: inline-block;
          right: 20px;
        }

        &:first-child {
          padding-top: 20px;

          .link-toggle {
            top: 37px;
          }
        }

        &:nth-last-child(2) {
          padding-bottom: 20px;
        }

        .header-submenu {
          &:nth-last-child(2) {
            left: initial;
            right: initial;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: calc(#{$page-width} - 40px)) {
  .header {
    &-item {
      padding: 0 30px 0 0;

      .link {
        padding: 15px 15px 15px 0;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .header {
    &-item {
      padding: 0 10px 0 0;

      .link {
        padding: 15px 20px 15px 0;
      }
    }
  }
}