@import "../../styles/settings/colors";
@import "../../styles/settings/fonts";

@import "../../styles/components/forms/buttons";

@import "../../styles/tools/mixins";

.button-download {
  float: left;
  margin: 30px 0;
  position: relative;
  text-align: center;
  width: 100%;

  .btn-item {
    @extend %btn-clear;
    width: fit-content;
    border: 1px solid $secondary-color;
    background-color: white;
    padding: 8px 16px;
    margin: auto;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 12px;

    .icon-item {
      position: relative;
      bottom: 3px;
    }

    .text {
      @include font($font-default, 20px, 800, $secondary-color);
      float: left;
      margin: 0;
      text-transform: uppercase;
      width: 100%;
      vertical-align: top;
    }

    svg {
      stroke: #EF7D05;
      position: relative;
      bottom: 1px;
    }

    &:hover {
      background-color: $secondary-color;
      cursor: pointer;

      .text {
        color: white;
      }

      svg {
        stroke: white;
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin: 15px 0;
    .btn-item {
      width: 90%;
      max-width: 90%;
      padding: 10px 30px 10px 10px;

      .icon-item {
        right: 0px;
      }
      .text {
        @include font($font-default, 16px, 600, $secondary-color);
      }
    }
  }
}
