@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/components/animations';

@import '../../../../styles/tools/mixins';

.ships-tabs-list {
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;
  z-index: 2;

  &--item {
    margin: 0;
    position: relative;
  }

  &--gallery {
    float: left;
    position: relative;
    width: 100%;
    vertical-align: top;

    &--slider {
      float: left;
      padding: 0;
      position: relative;
      width: 100%;

      .slick {
        &-list,
        &-track { 
          touch-action: pan-y;
        }
    
        &-list {
          float: left;
          padding: 0 0 30px;
          overflow: hidden;
          position: relative;
          width: 100%;
        }
    
        &-slide {
          align-items: flex-start;
          display: inline-flex;
          justify-content: center;
    
          > div {
            align-items: flex-start;
            display: inline-flex;
            justify-content: center;
            float: left;
            position: relative;
            width: 100%;
          }
        }
    
        &-slider {
          float: left;
          position: relative;
          width: 100%;
          vertical-align: top;
        }
    
        &-track {
          float: left;
          position: relative;
          width: 100%;
          vertical-align: top;
        }
      }
    }

  }
}