@import '../../styles/settings/grid';

.gallery {
  float: left;
  margin: 30px 0;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  &--content {
    max-width: $page-width;
    margin: 0 auto;
    width: 100%;
    vertical-align: top;
  }

  &:after {
    background: linear-gradient(180deg, #B9D2DB 30.73%, rgba(255, 255, 255, 0) 100%);
    content: '';
    height: 660px;
    left: 0;
    position: absolute;
    pointer-events: none;
    top: calc(50% + 20px);
    width: 100%;
    user-select: none;
    z-index: -1;
  }
}