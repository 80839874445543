@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/components/animations';
@import '../../../styles/components/forms/buttons';

@import '../../../styles/tools/mixins';

.video-player-controls {
  background-color: rgba($neutra-04, 0.2);
  padding: 20px 0 15px;
  pointer-events: none;
  position: relative;
  margin-top: -59px;
  width: 100%;
  z-index: 22;

  .btn-item,
  .slider-item {
    float: left;
    pointer-events: auto;
    position: relative;
    vertical-align: top;
  }

  .btn-item {
    @extend %btn-clear;
    margin: 0 20px;
    padding: 0;
    text-align: center;
    width: 40px;

    span {
      @include rem('font-size', 14px);
      color: $neutra;
      padding: 5px 0;

      &:last-child {
        display: none;
      }
    }

    &[data-active="true"] {
      span {
        display: none;

        &:last-child {
          display: inline-block;
        }
      }
    }
  }

  .slider-item {
    width: calc(100% - 160px);

    input[type=range] {
      margin: 10px 0;
      width: 100%;
      -webkit-appearance: none;
    }

    input[type=range]:focus {
      outline: none;
    }

    input[type=range] {
      background-color: rgba($secondary-color, 0.4);
      position: relative;
      top: 1px;
      transition: all 400ms $base-ease;
      
      &::-webkit-slider-runnable-track {
        background: $neutra;
        border-radius: 0;
        cursor: pointer;
        height: 2px;
        width: 100%;
      }

      &::-webkit-slider-thumb {
        background: $secondary-color;
        border-radius: 100%;
        cursor: pointer;
        height: 16px;
        margin-top: -7px;
        transition: all 400ms $base-ease;
        width: 16px;
        -webkit-appearance: none;
      }

      &::-moz-range-thumb {
        background: $secondary-color;
        border-radius: 0;
        cursor: pointer;
        height: 16px;
        margin-top: -7px;
        transition: all 400ms $base-ease;
        width: 16px;
        -webkit-appearance: none;
      }

      &:focus {
        &::-webkit-slider-runnable-track {
          background: rgba($secondary-color, 0.4);
        }  
      }
    }
  }
}