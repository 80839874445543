@import '../../../styles/settings/grid';

.ships-filter {
  float: left;
  min-height: 480px;
  padding: 60px 70px 40px;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  .not-found {
    z-index: 20;
    color: #fff;
    font-size: 26px;
    text-align: center;
    position: relative;
    margin: 0 0;
    font-family: "TheSans", sans-serif;
    font-weight: 600;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }

  &--background {
    left: 0;
    height: 350px;
    position: absolute;
    top: 70px;
    width: 100%;
    vertical-align: top;
  }

  &--list {
    float: none;
    max-width: $container-width;
    list-style: none;
    margin: 0;
    padding: 60px 20px;
    text-align: left;
    width: 100%;
  }

  &--item {
    float: left;
    padding: 15px;
    position: relative;
    width: calc(100% / 3);
    vertical-align: top;

    .ships-card {
      .title {
        color: $primary-color;
      }
    }
  }

  &[data-type="tipo_1"] {
    .ships-filter {
      &--list {
        .ships-filter--item {
          .ships-card {
            .title {
              color: $primary-color;
            }
          }

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            .ships-card {
              .title {
                color: $neutra;
              }
            }
          }
        }
      }
    }
  }

  &[data-type="tipo_2"] {
    .ships-filter {
      &--list {
        .ships-filter--item {
          .ships-card {
            .title {
              color: $secondary-color;
            }
          }

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            .ships-card {
              .title {
                color: $neutra;
              }
            }
          }
        }
      }
    }
  }

  &[data-type="tipo_3"] {
    .ships-filter {
      &--list {
        .ships-filter--item {
          .ships-card {
            .title {
              color: $neutra-05;
            }
          }

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            .ships-card {
              .title {
                color: $neutra;
              }
            }
          }
        }
      }
    }
  }

  &[data-type="tipo_4"] {
    .ships-filter {
      &--list {
        .ships-filter--item {
          .ships-card {
            .title {
              color: $secondary-dark;
            }
          }

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            .ships-card {
              .title {
                color: $neutra;
              }
            }
          }
        }
      }
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .ships-filter {
    min-height: initial;
    padding: 60px 0 40px;
    &.empty {
      min-height: 450px;
    }

    &--list {
      padding: 50px 10px 60px;
    }

    &--item {
      padding: 10px;
      width: calc(100% / 2);
    }

    &[data-type="tipo_1"] {
      .ships-filter {
        &--item {
          &:nth-child(3) {
            .ships-card {
              .title {
                color: $primary-color !important;
              }
            }
          }
        }
      }
    }
  
    &[data-type="tipo_2"] {
      .ships-filter {
        &--item {
          &:nth-child(3) {
            .ships-card {
              .title {
                color: $secondary-color !important;
              }
            }
          }
        }
      }
    }
  
    &[data-type="tipo_3"] {
      .ships-filter {
        &--item {
          &:nth-child(3) {
            .ships-card {
              .title {
                color: $neutra-05 !important;
              }
            }
          }
        }
      }
    }
  
    &[data-type="tipo_4"] {
      .ships-filter {
        &--item {
          &:nth-child(3) {
            .ships-card {
              .title {
                color: $secondary-dark !important;
              }
            }
          }
        }
      }
    }
    .not-found {
      width: 100%;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .ships-filter {
    &--list {
      padding: 50px 10px 60px;
    }

    &--item {
      padding: 10px;
      width: 100%;
    }

    &[data-type="tipo_1"] {
      .ships-filter {
        &--item {
          &:nth-child(2) {
            .ships-card {
              .title {
                color: $primary-color !important;
              }
            }
          }
        }
      }
    }
  
    &[data-type="tipo_2"] {
      .ships-filter {
        &--item {
          &:nth-child(2) {
            .ships-card {
              .title {
                color: $secondary-color !important;
              }
            }
          }
        }
      }
    }
  
    &[data-type="tipo_3"] {
      .ships-filter {
        &--item {
          &:nth-child(2) {
            .ships-card {
              .title {
                color: $neutra-05 !important;
              }
            }
          }
        }
      }
    }
  
    &[data-type="tipo_4"] {
      .ships-filter {
        &--item {
          &:nth-child(2) {
            .ships-card {
              .title {
                color: $secondary-dark !important;
              }
            }
          }
        }
      }
    }
  }
}