@import '../../../styles/settings/colors';
@import '../../../styles/settings/grid';

@import '../../../styles/components/animations';

.gallery-slider {
  animation: fade-top-in 0.5s 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  left: 50%;
  max-width: $page-width;
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  transform: translate(-50%, 0);
  width: calc(100% - 240px);

  &[data-direction="prev"] {
    .gallery-slider-item {
      &[data-active="true"] {
        animation: slide-in-left 0.5s $base-ease both;
        transform: translateX(-100%);
      }

      &[data-last="true"] {
        animation: slide-out-right 0.5s $base-ease both;
        transform: translateX(0%);
      }
    }
  }

  &[data-direction="next"] {
    .gallery-slider-item {
      &[data-active="true"] {
        animation: slide-in-right 0.5s $base-ease both;
        transform: translateX(100%);
      }

      &[data-last="true"] {
        animation: slide-out-left 0.5s $base-ease both;
        transform: translateX(0%);
      }
    }
  }

  @keyframes fade-top-in {
    0% {
      opacity: 0;
      transform: translate(-50%, 50%);
    }
    100% {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .gallery-slider {
    width: calc(100% - 90px);
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .gallery-slider {
    height: calc(100vh - 190px);
    padding: 0;
    position: relative;
    top: 0;
    transform: translate(0, 0);
    width: calc(100% - 40px);
  }
}