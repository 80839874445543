@import '../../styles/settings/colors';
@import '../../styles/settings/grid';

.block-print {
  float: left;
  margin: 30px 0 60px;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  &--container
  &--image {
    position: relative;
    vertical-align: top;
  }

  &--container {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    flex-flow: row wrap;
    max-width: $container-width;
    width: 100%;
  }

  &--image {
    width: 50%;

    .image {
      filter: drop-shadow(0px 4px 40px rgba(25, 38, 57, 0.24));
      border-radius: 5px;
      overflow: hidden;
      height: auto;
      float: left;
      width: calc(100% - 60px);
      z-index: 1;
    }

    &:after {
      background-color: $primary-color;
      content: '';
      height: 8px;
      left: -44px;
      position: absolute;
      top: calc(50% - 40px);
      transform: translate(0, -50%);
      width: 88px;
      z-index: 2;
    }
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .block-print {
    &--container {
      padding: 20px;
    }

    &--image {
      &:after {
        height: 4px;
        left: -21px;
        width: 42px;
      }
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .block-print {
    &--container {
      align-items: flex-start;
      max-width: 640px;
      padding: 20px 0;
    }

    &--image {
      .image {
        width: calc(100% - 15px);
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .block-print {
    &--container {
      max-width: 640px;
      padding: 20px;
    }

    &--image {
      .image {
        width: calc(100% - 30px);
      }

      &:after {
        top: calc(50% - 20px);
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .block-print {
    &--image {
      width: 100%;
      
      .image {
        width: 100%;
      }
    }
  }
}
