@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';
@import '../../../styles/settings/grid';

@import '../../../styles/components/animations';
@import '../../../styles/components/typography';
@import '../../../styles/tools/mixins';

.header {
  &-menu {
    align-items: flex-end;
    display: inline-flex;
    justify-content: space-between;
    flex-flow: row wrap;
    float: right;
    margin: 0 auto;
    max-width: $page-width;
    padding: 5px 20px;
    position: relative;
    transition: all 400ms $base-ease;
    text-align: right;
    width: 100%;

    &--logo {
      float: left;
      margin: 0 0 0 120px;
      position: relative;
      top: -10px;
      transition: all 200ms $base-ease;

      svg {
        @extend %logo;
        float: left;
        position: relative;
        width: 95px;
        height: 55px;

        .fill {
          &-st0,
          &-st1,
          &-st2 {
            fill: $neutra;
          }
        }
      }
    }
  }

  &[data-media="xs"],
  &[data-media="sm"],
  &[data-media="md"] {
    padding: 0;
    
    .header-menu {
      height: 60px;
      position: absolute;

      &--logo {
        left: 70px;
        margin: 0;
        position: absolute;
        top: 30px;
        z-index: 20;

        svg {
          height: 55px;
          width: auto;
        }
      }
    }

    &[data-active="true"],
    &[data-scroll="true"] {
      .header-menu {
        &--logo {
          left: 20px;
          top: 12px;

          svg {
            @extend %logo;
            height: 35px;
          }
        }
      }
    }

    &[data-active="true"] {
      .header-menu {
        height: 100vh;
      }
    }
  }
}

@media only screen and (max-width : $page-width) {
  .header {
    &-menu {
      &--logo {
        margin: 0 0 0 170px;
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .header {
    &[data-media="xs"],
    &[data-media="sm"],
    &[data-media="md"] {
      .header-menu {
        &--logo {
          left: 25px;
        }
      }
    }
  }
}