@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/tools/mixins';

.text {
  float: left;
  margin: 10px 0 20px;
  position: relative;
  width: 100%;
  text-align: center;
  vertical-align: top;

  &--container {
    @include font($font-default, 16px, 300, $neutra-06);
    line-height: 1.6em;
    max-width: 690px;
    padding: 0 20px;
    position: relative;
    text-align: left;
    width: 100%;
    vertical-align: top;

    > p {
      margin: 0 0 20px;
      width: 100%;
      word-break: break-word;

      > a {
        @include font($font-default, 16px, 700,  $secondary-color);
        display: inline;
      }
      
      strong,
      em,
      i,
      span {
        display: inline;
      }

      &:empty {
        display: none;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  & + .card {
    margin-top: 0;
  }

  &[data-line="true"] {
    .text {
      &--container {
        margin-bottom: 30px;

        &:before {
          background-color: $secondary-color;
          content: '';
          height: 4px;
          left: 20px;
          position: absolute;
          top: calc(100% - 4px);
          width: 90px;
        }
      }
    }
  }

  &-align {
    &-left {
      text-align: left;
    }
    &-right {
      text-align: right;
    }
    &-center {
      text-align: center;
    }
    &-justify {
      text-align: justify;
      text-justify: inter-word;
    }
  }
}
