@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.list-group-item {
  border: none;
  border-right: 1px solid $neutra-05;
  position: relative;
  width: 100%;
  &:first-child {
    border-left: 1px solid $neutra-05;
  }

  &--content,
  &--title {
    position: relative;
    text-align: left;
    vertical-align: top;
  }

  &--content {
    background-color: transparent;
    height: 100%;
    padding: 15px;
    width: 100%;

    .description {
      @include font($font-default, 16px, 400, $primary-dark);
      line-height: 1.6em;
      margin: 0;
    }
  }

  &--title {
    align-content: space-between;
    align-items: flex-end;
    display: inline-flex;
    justify-content: flex-start;
    flex-flow: row wrap;
    height: 165px;
    padding: 20px 15px;
    width: 100%;

    .image {
      margin: 0 0 10px;
    }

    .title {
      @include font($font-default, 20px, 400, $neutra);
      margin: 0;
      padding-right: 10px;
      vertical-align: bottom;
      width: 100%;

      &:empty {
        display: none;
      }
    }
  }

  &:nth-child(2n+1) {
    .list-group-item {
      &--title {
        background-color: $primary-light;
      }
    }
  }

  &:nth-child(2n+2) {
    .list-group-item {
      &--title {
        background-color: $primary-dark;
      }
    }
  }

  @media only screen and (max-width : 1023px) {
    width: 25%;
  }

  @media only screen and (max-width : 768px) {
    width: 33%;
  }

  @media only screen and (max-width : 480px) {
    width: 50%;
  }
}
