@import '../../styles/settings/settings';

@import '../../styles/tools/mixins';

.block-split {
  float: left;
  margin: 60px 0;
  overflow: hidden;
  padding: 60px 0;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  &--background {
    left: 0;
    height: calc(100% - 120px);
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;

    svg {
      opacity: 0.4;

      path {
        fill: $secondary-dark;
      }
    }
  }

  &--container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    margin: 0 auto;
    max-width: $container-width;
    text-align: left;
    width: 100%;
    vertical-align: top;
  }

  &--info,
  &--image {
    float: left;
    position: relative;
    width: 100%;
    vertical-align: top;
  }

  &--info {
    align-items: flex-end;
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 0 30px 80px;
    
    &--text {
      float: left;
      position: relative;
      width: 100%;
      vertical-align: top;

      .description,
      .title {
        margin: 0;
        float: left;
        position: relative;
        width: 100%;
      }

      .description {
        > p {
          @include font($font-default, 16px, 400, $primary-dark);
          line-height: 1.6em;

          &:first-child {
            margin-top: 0;
          }
        }
      }

      .title {
        @include font($font-default, 40px, 600, $primary-dark);
        margin: 0 0 40px;
        text-indent: -2px;
      }
    }

    .btn-border {
      margin-bottom: 60px;

      .icon {
        @include rem('font-size', 10px);
      }
    }
  }

  &--image {
    padding: 0 30px 0 0;

    .image {
      filter: drop-shadow(0px 4px 40px rgba(25, 38, 57, 0.24));
      border-radius: 5px;
      float: left;
      overflow: hidden;
      width: 100%;
      vertical-align: top;
    }

    &:after {
      background-color: $secondary-dark;
      content: '';
      height: 8px;
      left: -44px;
      position: absolute;
      top: calc(50% - 40px);
      transform: translate(0, -50%);
      width: 88px;
      z-index: 2;
    }
  }

  &:before {
    background-color: $neutra-05;
    content: '';
    height: calc(100% - 120px);
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .block-split {
    &--background {
      left: 0;
      width: 1400px;
    }

    &--container {
      display: inline-flex;
    }

    &--image,
    &--info {
      padding: 20px;
      width: 50%;
    }

    &--image {
      &:after {
        left: -25px;
      }
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .block-split {
    margin: 30px 0 60px;

    &--container {
      align-items: flex-start;
      justify-content: center;
      flex-flow: row wrap;
    }

    &--image {
      order: 2;
      padding: 20px;
      width: calc(100% - (90px * 2));

      .image {
        filter: drop-shadow(0px 4px 10px rgba(25, 38, 57, 0.24));
        z-index: 2;
      }

      &:before {
        background-color: $neutra;
        content: '';
        height: 100%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, 0);
        top: calc(50% - 40px);
        width: 100vw;
        z-index: 1;
      }
    }

    &--info {
      order: 1;
      padding: 0;
      width: calc(100% - (75px * 2));

      .btn-border {
        margin: 60px 0;
      }
    }

    &:before {
      height: 100%;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .block-split {
    &--info,
    &--image {
      padding: 20px;
      width: 100%;
    }

    &--info {
      padding-bottom: 0;
      padding-top: 0;
    }
  }
}