.webdoor-item {
  display: none;
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  will-change: transform;

  .video {
    left: 0;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    
    iframe {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &:after {
      background: linear-gradient(0deg, rgba(25, 38, 57, 0.2), rgba(25, 38, 57, 0.2));
      background-blend-mode: multiply;
      content: '';
      height: 100%;
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;
    }
  }

  picture {
    float: left;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;

    img {
      float: left;
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      pointer-events: none;
      top: 0;
      width: 100%;
    }
  }

  &[data-active="true"],
  &[data-last="true"] {
    display: inline-block;
  }

  &[data-active="true"] {
    z-index: 3;
  }

  &[data-last="true"] {
    z-index: 1;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .webdoor-item {
    padding: 0;

    picture {
      height: 100vh;
      padding: 0;
      position: relative;
      width: 100%;

      img {
        float: left;
        height: 100%;
        position: relative;
        width: 100%;
      }
    }

    &[data-active="true"] {
      position: relative;
    }
  }
}