@import '../../styles/settings/colors';
@import '../../styles/settings/grid';

.social-media {
  background-color: $primary-light;
  margin: 30px 0 0;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  &--container,
  &--wrapper {
    position: relative;
    width: 100%;
    vertical-align: top;
  }

  &--container {
    max-width: $page-width;
    padding: 0 0 50px;
    text-align: left;
  }

  &--wrapper {
    .title-text {
      margin: 60px 0;

      &[data-type="h2"] {
        .text {
          color: $neutra;
        }
      }
    }
  }
}