@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/components/animations';

@import '../../../../styles/tools/mixins';

.ships-tabs-item {
  float: left;
  height: 160px;
  margin: 0 5px;
  position: relative;
  text-align: center;
  width: 190px;
  vertical-align: top;

  .item-link {
    float: left;
    text-decoration: none;
    width: auto;

    .count,
    .text {
      @include font($font-default, 16px, 800, $neutra-02);
      float: left;
      line-height: 1em;
      position: relative;
      width: 100%;
      vertical-align: top;
    }

    .count {
      @include rem('font-size', 67px);
      line-height: 0.8;
      text-indent: -20px;
    }

    .icon {
      @include rem('font-size', 65px);
      color: $neutra-02;
      float: none;
      margin: 0;
      position: relative;
      top: -15px;
    }
  
    .text {
      font-weight: 800;
    }

    &:after {
      background-color: $primary-color;
      border-radius: 8px;
      bottom: -24px;
      content: '';
      height: 9px;
      left: 50%;
      position: absolute;
      transform: translate(-50%, 0);
      transition: width 400ms $base-ease;
      width: 0;
      z-index: 2;
    }

    &[data-active="true"],
    &.active {
      .count,
      .icon,
      .text {
        color: $primary-color;
      }

      &:after {
        width: 130px;
      }
    }
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .ships-tabs-item {
    padding: 0;
    width: 180px;

    .item-link {
      &.active {
        &:after {
          width: 107px;
        }
      }
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .ships-tabs-item {
    width: 190px;
  }
}