.golden-rules-container {
    max-width: 1276px;
    padding: 0 20px;

    &--list {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &--item{
        margin-bottom: 20px;
        border: 1px solid #B9D2DB;
        padding: 27px 40px;
        background: #fff;
    }

    @media screen and (min-width: 901px) {
        &--item{
            width: calc(50% - 16px);
            margin-bottom: 32px;
            margin-right: 32px;
            &:nth-child(2n) {
                margin-right: 0;
            }
        }
    }
}