@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/components/forms/buttons';
@import '../../styles/tools/mixins';

.search {
  float: none;
  margin: 0 20px 0 5px;
  position: relative;
  width: auto;
  vertical-align: top;

  &-btn {
    @extend %btn-clear;
    float: left;
    padding: 0;
    position: relative;
    top: 3px;

    .icon {
      @include rem('font-size', 20px);
      color: $neutra;
    }
  }
}