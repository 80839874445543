@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/tools/mixins';

.highlights {
  background-color: $secondary-dark;
  float: left;
  height: 228px;
  position: relative;
  pointer-events: auto;
  width: 100%;
  vertical-align: top;

  &--background,
  &--content,
  &--title {
    position: relative;
    width: 100%;
    vertical-align: top;
  }

  &--background {
    left: 0;
    height: 100%;
    position: absolute;
    width: 100%;

    svg {
      height: 100%;
      width: 100%;
      
      path {
        fill: $secondary-dark-300;
      }
    }
  }

  &--content {
    height: 100%;
  }

  &--title {
    left: 168px;
    margin: 20px 0 0;
    padding: 0 50px;
    position: absolute;
    top: 0;
    width: calc(100% - (168px * 2));

    p {
      @include font($font-default, 14px, 800, $neutra);
      float: left;
      text-transform: uppercase;
    }
  }

  .background-wave-lines {
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, 0);
    width: 100%;

    > svg {
      height: 100%;
      width: 100%;
  
      path {
        fill: rgba($neutra, 0.1);
      }
    }
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .highlights {
    &--title {
      left: 0;
      padding: 0 70px;
      width: 100%;
    }
  }
}