
@import '../../styles/settings/colors';

.webdoor-interna {
  float: left;
  height: 145px;
  position: relative;
  width: 100%;
  vertical-align: top;
  background-color: rgba(25,38,57,.8);

  img {
    float: left;
    height: 100%;
    object-fit: cover;
    width: 100%;
    vertical-align: top;
  }

  &:after {
    background-color: $neutra-04;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0.8;
    position: absolute;
    top: 0;
    width: 170px;
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .webdoor-interna {
    height: 125px;
    
    &:after {
      display: none;
    }
  }
}
