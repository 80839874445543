@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';
@import '../../styles/settings/grid';

@import '../../styles/components/typography';

@import '../../styles/tools/mixins';

.title-text {
  float: left;
  margin: 30px 0;
  padding: 0 20px;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  .text {
    float: none;
    margin: 0;
    max-width: $container-width;
    line-height: 1em;
    position: relative;
    text-align: center;
    width: 100%;
    vertical-align: top;
  }

  &[data-type="h1"] {
    .text {
      @extend %title-super;
      margin: 30px 0 10px;
    }
  }

  &[data-type="h2"] {
    .text {
      @extend %title-main;
    }
  }

  &[data-type="h3"] {
    .text {
      @extend %title-super;
      color: $primary-dark;
      text-transform: none;
    }
  }
}