@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.footer-social {
  position: relative;
  vertical-align: top;

  .item-link {
    margin: 0 15px;
    position: relative;
    vertical-align: top;

    .icon {
      @include rem('font-size', 16px);
      color: $primary-color;
      float: left;
      position: relative;
      vertical-align: top;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .footer-social {
    text-align: center;
    width: 100%;
  }
}