@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/tools/mixins';

.timeline {
  float: left;
  position: relative;
  width: 100%;
  vertical-align: top;

  &--return {
    left: 0;
    position: absolute;
    top: 0;
    width: auto;
    z-index: 9;

    .btn-border {
      &.back {
        background-color: $neutra;
        border-color: transparent;
        color: $secondary-color;
        height: auto;
        padding: 17px 15px;

        .icon {
          @include rem('font-size', 10px);
          margin: 0 15px 0 0;
        }
      }
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .timeline {
    &--return {
      position: fixed;
    }
  }
}