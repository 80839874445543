@import '../../styles/settings/fonts';

@import '../../styles/tools/mixins';

.ships-page {
  float: left;
  position: relative;
  width: 100%;
  vertical-align: top;

  &--footer {
    float: left;
    margin: 20px 0 60px;
    position: relative;
    text-align: center;
    width: 100%;

    .btn {
      border: none;
      float: none;

      .icon,
      .text {
        float: left;
        margin: 0;
      }

      .icon {
        @include rem('font-size', 9px);
      }

      .text {
        margin: 0 0 0 10px;
        width: auto;
      }
    }
  }
}