.ships-item-relationships-list {
  float: none;
  margin: 0 auto;
  list-style: none;
  padding: 0;
  max-width: 760px;
  width: 100%;

  &--gallery {
    float: left;
    position: relative;
    width: 100%;
    vertical-align: top;

    .slick {
      &-list,
      &-track { 
        touch-action: pan-y;
      }

      &-list {
        float: left;
        padding: 0;
        overflow: hidden;
        position: relative;
        width: 100%;
      }

      &-slide {
        align-items: flex-start;
        display: inline-flex;
        justify-content: center;

        > div {
          align-items: flex-start;
          display: inline-flex;
          justify-content: center;
          float: left;
          position: relative;
          width: 100%;
        }
      }

      &-slider {
        float: left;
        position: relative;
        width: 100%;
      }

      &-track {
        float: left;
        position: relative;
        width: 100%;
        vertical-align: top;
      }
    }

    .ships-card {
      padding: 10px 10px 60px;
      width: 350px;
    }
  }

  &--item {
    float: left;
    margin: 0;
    padding: 0 10px;
    width: 50%;
    vertical-align: top;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .ships-item-relationships-list {
    float: left;
    max-width: none;
    padding: 0;

    &--gallery {
      .ships-card {
        padding: 10px 0 60px;
        width: 350px;
      }

      .slick {
        &-slide {
          > div {
            padding: 0 10px;
          }
        }

        &-list {
          padding: 0 10px;
        }
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .ships-item-relationships-list {
    &--gallery {
      .ships-card {
        padding: 10px 0 60px;
        width: 315px;
      }
    }
  }
}
