@import '../../../../../styles/settings/colors';
@import '../../../../../styles/settings/fonts';

@import '../../../../../styles/tools/mixins';

.ships-card-modal-list {
  border: 1px solid rgba($black-900, 0.1);
  border-left: none;
  border-right: none;
  float: right;
  margin: 0;
  max-width: calc(100% - 35px);
  padding: 10px 0;
  position: relative;
  width: 100%;

  &--item {
    float: left;
    margin: 10px 0;
    position: relative;
    width: 100%;
    vertical-align: top;

    .label,
    .value {
      float: left;
      position: relative;
      width: 50%;
      vertical-align: top;
    }

    .label {
      @include font($font-default, 16px, 400, $black-900);
    }

    .value {
      @include font($font-default, 16px, 400, $neutra-03);
      text-align: right;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .ships-card-modal-list {
    border-bottom: none;
    margin-bottom: 10px;
  }
}