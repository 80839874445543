@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/components/forms/buttons';
@import '../../../styles/tools/mixins';

.search-content {
  background-color: rgba($neutra-04, 0.7);
  left: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100vw;

  &--form {
    align-items: center;
    align-content: center;
    background-color: $neutra;
    display: inline-flex;
    justify-content: space-between;
    flex-flow: row wrap;
    float: left;
    height: 80px;
    padding: 0 20px;
    width: 100%;
    vertical-align: top;
  }

  &--controls {
    .btn {
      &-close,
      &-search {
        @extend %btn-clear;
        float: left;
      }

      &-close {
        .icon {
          @include rem('font-size', 24px);
          color: $neutra-02;
          float: left;
        }
      }

      &-search {
        padding: 5px 30px;

        .text {
          @include font($font-default, 14px, 700, $secondary-color);
          float: left;
          margin: 0;
          text-transform: uppercase;
        }
      }
    }
  }

  &--search {
    float: left;
    width: calc(100% - 200px);

    .icon,
    .input-search {
      float: left;
      position: relative;
      vertical-align: top;
    }

    .icon {
      @include rem('font-size', 20px);
      color: $primary-color;
      margin: 0 20px 0 0;
    }

    .input-search {
      @include font($font-default, 20px, 400, $black-900);
      border: none;
      padding: 0 0 0 10px;
      width: calc(100% - 40px);
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .search-content {
    &--controls {
      display: flex;
      max-width: 130px;
      .btn-search {
        padding: 5px 20px;
      }
    }

    &--search {
      width: calc(100% - 130px);

      .icon {
        margin-right: 10px;
      }
    }
  }
}