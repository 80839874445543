@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/components/forms/buttons';

@import '../../styles/tools/mixins';

.pagination {
  float: left;
  margin: 60px 0;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  &--action,
  &--info {
    margin: 0;
    position: relative;
    vertical-align: top;
  }

  &--action {
    @extend %btn-clear;
    margin: 0 10px;
    padding: 5px 10px;

    .icon {
      @include rem('font-size', 20px);
      color: $primary-color;
    }

    &:disabled {
      .icon {
        color: $neutra-02;
      }
    }
  }

  &--info {
    @include font($font-default, 14px, 400, $neutra);
    background-color: $secondary-color;
    border-radius: 5px;
    line-height: 1em;
    overflow: hidden;
    padding: 8px 12px;

    span {
      vertical-align: top;
    }
  }
}