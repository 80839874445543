.client-slider-item {
  align-items: center;
  display: inline-flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0;
  position: relative;
  width: 100%;
  vertical-align: top;
  will-change: transform;

  .link-client {
    float: left;
    padding: 0;
    position: relative;
    width: 100%;
    text-decoration: none;

    img {
      max-height: 110px;
      opacity: 1;
      position: relative;
      width: auto;
      vertical-align: top;
    }
  }
}
