/* --- ICONS --- */
%icon,
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-checked:before {
  content: "\e914";
}
.icon-ahts:before {
  content: "\e910";
}
.icon-osrv:before {
  content: "\e911";
}
.icon-psv:before {
  content: "\e912";
}
.icon-rsv:before {
  content: "\e913";
}
.icon-weight:before {
  content: "\e90d";
}
.icon-kg:before {
  content: "\e90e";
}
.icon-area:before {
  content: "\e90f";
}
.icon-close:before {
  content: "\e90c";
}
.icon-instagram:before {
  content: "\e90a";
}
.icon-linkedin:before {
  content: "\e90b";
}
.icon-arrow-down-round:before {
  content: "\e900";
}
%icon-arrow-left-l,
.icon-arrow-left-l:before {
  content: "\e901";
}
.icon-arrow-left-min:before {
  content: "\e902";
}
%icon-arrow-left,
.icon-arrow-left:before {
  content: "\e903";
}
%icon-arrow-right-l,
.icon-arrow-right-l:before {
  content: "\e904";
}
.icon-arrow-right-min:before {
  content: "\e905";
}
%icon-arrow-right,
.icon-arrow-right:before {
  content: "\e906";
}
.icon-hamburger:before {
  content: "\e907";
}
.icon-logo:before {
  content: "\e908";
}
.icon-search:before {
  content: "\e909";
}
.icon-play:before {
  content: "\ea1c";
}
.icon-pause:before {
  content: "\ea1d";
}
.icon-volume-medium:before {
  content: "\ea27";
}
.icon-volume-mute:before {
  content: "\ea2a";
}
