@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.menu-item {
  background-color: $neutra-05;
  max-width: 205px;
  margin: 0 4px;
  position: relative;
  width: 100%;

  .text {
    @include font($font-default, 16px, 800, $primary-dark);
    float: left;
    margin: 0;
    padding: 10px 0;
    text-transform: uppercase;
    width: 100%;
    vertical-align: top;
  }

  &[data-active="true"] {
    background-color: $primary-dark;
    
    .text {
      color: $neutra;
      font-weight: 400;
    }
  }
}