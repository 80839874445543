@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';
@import '../../../styles/settings/grid';

@import '../../../styles/components/typography';

@import '../../../styles/tools/mixins';

.timeline-item {
  animation-delay: 1s;
  float: left;
  height: 100vh;
  pointer-events: auto; // none
  position: absolute;
  width: 100%;
  vertical-align: top;

  &--image,
  &--index,
  &--info {
    float: left;
    height: 100%;
    position: relative;
    width: 50%;
  }

  &--image {
    background-color: transparent;
    z-index: 2;
    pointer-events: none;

    .image-main,
    .image-second,
    .image-three {
      filter: drop-shadow(-8px 4px 72px rgba($primary-dark, 0.8));
      opacity: 0;
      pointer-events: none;
      position: absolute;
      transition: all 600ms $base-ease;
      transition-delay: 3000ms;
      width: 100%;
      user-select: none;
      z-index: 2;
    }

    .image-main {
      max-width: 305px;
      right: -90px;
      transform: translate(0, -50%);
      transform-origin: right center;
      top: 100%;
      z-index: 3;
    }

    .image-second {
      left: 50%;
      max-width: 400px;
      top: 50%;
      transform: translate(-50%, 0);
      z-index: 2;
    }

    .image-three {
      left: calc(50% - 80px);
      max-width: 320px;
      top: 100%;
      transform: translate(0, -50%);
      z-index: 1;
    }

    .year {
      @include font($font-default, 100px, 400, $neutra);
      line-height: 1em;
      right: 50px;
      margin: 0;
      max-width: 250px;
      opacity: 0;
      position: absolute;
      top: 50%;
      text-align: left;
      transition: all 600ms $base-ease;
      transition-delay: 3000ms;
      transform-origin: left center;
      z-index: 10;
    }
  }

  &--index {
    padding: 110px 0 0;
    width: 100%;

    .year {
      @include font($font-default, 60px, 400, $neutra);
      float: left;
      margin: 30px 0;
      padding: 0 0 0 220px;
      position: relative;
      width: 100%;
      vertical-align: top;

      &:before {
        background-color: $secondary-color;
        content: '';
        height: 14px;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        width: 180px;
      }
    }
  }
  &--scroll {
    display: flex;
    flex-flow: column;
    max-height: 100%;
    padding: 20px 20px 20px 0;
    overflow-y: auto;
    ::-webkit-scrollbar {
        width: 4px;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 3px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 3px;
        box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    }
  }
  &--info {
    align-content: center;
    align-items: center;
    background-color: transparent;
    display: inline-flex;
    flex-flow: column wrap;
    justify-content: center;
    opacity: 0;
    top: 100%;
    text-align: center;
    transition: all 1000ms $base-ease;
    transition-delay: 2500ms;
    z-index: 1;

    .description,
    .title {
      float: none;
      opacity: 0;
      max-width: 440px;
      position: relative;
      text-align: left;
      top: 100px;
      transition: all 800ms $base-ease;
      transition-delay: 3000ms;
      width: 100%;
      z-index: 3;
    }

    .description {
      @include font($font-default, 16px, 400, $primary-dark);
      line-height: 1.4em;

      > p {
        &:first-child {
          margin-top: 0;
        }

        a {
          font-weight: bold;
          color: #EF7D05;
        }
      }
    }

    .title {
      @extend %title-super;
      color: $primary-dark;
      font-weight: 700;
      line-height: 1em;
      margin: 0 0 30px;
    }

    &:before {
      background-color: $neutra;
      content: '';
      height: 100%;
      right: 0;
      position: absolute;
      top: 0;
      transition: all 600ms $base-ease;
      transition-delay: 1000ms;
      transform-origin: right top;
      width: 0;
      vertical-align: top;
    }
  }

  &[data-active="true"] {
    z-index: 999;

    .timeline-item {
      &--image {
        .image-main {
          max-width: 405px;
          opacity: 1;
          top: calc(50% - 80px);
        }

        .image-second {
          opacity: 0.4;
          top: 90px;
        }

        .image-three {
          opacity: 0.4;
          top: calc(50% + 160px);
        }

        .year {
          @include rem('font-size', 200px);
          opacity: 0.1;
          right: 10px;
          top: 0;
        }
      }

      &--info {
        opacity: 1;
        top: 0;

        .description,
        .title {
          opacity: 1;
          top: 0;
        }

        &:before {
          opacity: 1;
          transition-delay: 2000ms;
          width: 100%;
        }
      }
    }
  }

  &[data-out="true"] {
    z-index: 998;

    .timeline-item {
      &--image {
        .image-main,
        .image-second,
        .image-three {
          opacity: 0;
          transition-delay: 400ms;
        }

        .image-main {
          max-width: 405px;
          top: 20px;
          transform: translate(0, 0);
          transition-duration: 600ms;
        }

        .image-second {
          transform: translate(-50%, 0%);
        }

        .image-second {
          top: -50px;
        }

        .image-three {
          top: 300px;
        }

        .year {
          @include rem('font-size', 200px);
          opacity: 0;
          right: 10px;
          top: 10px;
          transition-delay: 200ms;
        }
      }

      &--info {
        top: 0;
        opacity: 0;
        transition-delay: 500ms;

        .description,
        .title {
          transition-delay: 600ms;
        }

        .description {
          top: -100px;
        }

        .title {
          top: -100px;
        }

        &:before {
          opacity: 0;
          transition-delay: 600ms;
          width: 0;
        }
      }
    }
  }

  &[data-type="tipo_2"] {
    .timeline-item {
      &--image {
        .year {
          color: $primary-light;
        }
      }

      &--index {
        .year {
          color: $primary-light;

          &:before {
            background-color: $primary-light;
          }
        }
      }
    }
  }

  &[data-type="tipo_3"] {
    .timeline-item {
      &--image {
        .year {
          color: $secondary-dark;
        }
      }

      &--index {
        .year {
          color: $primary-light;

          &:before {
            background-color: $primary-light;
          }
        }
      }
    }
  }

  &[data-type="tipo_4"] {
    .timeline-item {
      &--index {
        .year {
          color: $neutra-05;

          &:before {
            background-color: $neutra-05;
          }
        }
      }
    }
  }
}

/* Large Devices, Wide Screens */
@media only screen and (max-width : $page-width) {
  .timeline-item {
    &--info {
      opacity: 0;
      padding: 0 100px 0 120px;
      top: 0%;
    }

    &[data-active="true"] {
      .timeline-item {
        &--info {

          .description,
          .title {
            max-width: none;
            width: 100%;
          }
        }

        &--image {
          .image-main {
            right: -70px;
            max-width: 275px;
            top: 50%;
          }

          .image-second {
            left: 60%;
            max-width: 265px;
            top: 20%;
          }

          .image-three {
            left: calc(50% - 30px);
            max-width: 210px;
            top: calc(50% + 150px);
          }
        }
      }
    }

    &[data-out="true"] {
      z-index: 998;
  
      .timeline-item {
        &--image {
          .image-main,
          .image-second,
          .image-three {
            opacity: 0;
            transition-delay: 400ms;
          }
  
          .image-main {
            max-width: 265px;
            right: -70px;
            top: 20px;
            transform: translate(0, 0);
            transition-duration: 600ms;
          }
          
          .image-second {
            left: 60%;
            max-width: 210px;
            top: -50px;
            transform: translate(-50%, 0%);
          }
  
          .image-three {
            left: calc(50% - 30px);
            max-width: 210px;
            top: 200px;
          }
        }
  
        &--info {
          padding: 0 120px;
          opacity: 0;
          top: 0;
          transition-delay: 500ms;
  
          .description,
          .title {
            max-width: none;
            transition-delay: 600ms;
          }
  
          .description {
            top: -100px;
          }
  
          .title {
            top: -100px;
          }
  
          &:before {
            opacity: 0;
            transition-delay: 600ms;
            width: 0;
          }
        }
      }
    }
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .timeline-item {
    &--info {
      opacity: 0;
      padding: 165px 85px 60px;
      top: 100%;
    }

    &[data-active="true"] {
      .timeline-item {
        &--info {
          display: inline-block;
          overflow-y: auto;
          opacity: 1;
          padding: 40px 85px 60px;
        }
      }
    }

    &[data-out="true"] {
      .timeline-item {
        &--info {
          display: inline-block;
          opacity: 0;
          padding: 40px 85px 60px;
        }
      }
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .timeline-item {
    left: initial;
    pointer-events: auto;
    position: relative;
    top: initial;
    z-index: 10;

    &--image,
    &--info {
      width: 100%;
    }
    &--index {
      padding-top: 50px;

      .year {
        font-size: 30px;
        padding-left: 20%;
        margin: 15px 0;
        &:before {
          width: 15%;
        }
      }
    }

    &[data-active],
    &[data-out] {
      height: auto;
      transform: none !important;

      .timeline-item {
        &--image {
          height: 470px;

          .image-main,
          .image-second,
          .image-three {
            opacity: 1;
          }

          .image-three {
            opacity: 0.7;
            left: 50%;
            max-width: 290px;
            top: 100%;
            transform: translate(-50%, -50%);
          }

          .image-second {
            left: 0;
            max-width: none;
            opacity: 0.2;
            top: 0;
            transform: translate(0, 0);
            width: 50%;
          }

          .image-main {
            left: initial;
            right: 0;
            max-width: none;
            transform: translate(0, 0);
            top: 60px;
            width: 450px;
          }
        }

        &--info {
          display: inline-block;
          height: auto;
          opacity: 1;
          padding: 165px 100px 60px;
          top: 0;

          .description,
          .title {
            max-width: none;
            opacity: 1;
            top: 0;
          }

          &:before {
            opacity: 1;
            width: 100%;
          }
        }
      }
    }

    &[data-type="tipo_1"] {
      background-color: $primary-color;
    }

    &[data-type="tipo_2"] {
      background-color: $secondary-color;
    }

    &[data-type="tipo_3"] {
      background-color: $neutra-05;
    }

    &[data-type="tipo_4"] {
      background-color: $secondary-dark;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .timeline-item {
    &[data-active],
    &[data-out] {
      .timeline-item {
        &--info {
          padding: 180px 10% 60px;
        }

        &--image {
          height: 420px;

          .image-main {
            width: 370px;
          }

          .image-second {
            width: 360px;
          }

          .image-three {
            width: 290px;
          }
        }
      }
    }
  }
}