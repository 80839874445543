@import '../../../styles/settings/grid';

@import '../../../styles/components/animations';

.tabs-body {
  display: block;
  margin: 0 auto;
  max-width: $container-width;
  padding: 0;
  position: relative;
  z-index: 5;

  &--item,
  &--list {
    position: relative;
    vertical-align: top;
  }

  &--item {
    display: none;
    padding: 40px 100px 0;

    &[data-active="true"] {
      display: inline-block;

      .tabs-item {
        &--image {
          animation: scale-up-center 0.4s $base-ease both;
        }

        &--info {
          animation: fade-in-bottom 0.6s $base-ease both;
        }
      }
    }
  }

  &--list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .tabs-body {
    &--item {
      padding: 20px;
    }
  }
}