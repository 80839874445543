@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.golden-rules-item {
    padding: 25px 0;
    margin: 0 auto;
    color: #263955;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    font-family: "TheSans", sans-serif;

    /*&--image {
        &.with-number {
            padding-left: 59px;
            position: relative;

            span.number {
                position: absolute;
                display: flex;
                width: 80px;
                height: 80px;
                border-radius: 80px;
                text-align: center;
                align-content: center;
                align-items: center;
                justify-content: center;
                justify-items: center;
                font-style: normal;
                font-weight: 600;
                font-size: 40px;
                line-height: 40px;
                color: #263955;
                background: rgba(38, 57, 85, 0.1);
                top: 50%;
                transform: translateY(-50%);
                left: 0;
            }
        }
    }*/
    
    &--info {
        h4{
            width: 100%;
            font-weight: 700;
            font-size: 18px;
            text-align: center;
        }

        p{
            margin: 0 0 15px 0;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
        }

        div{
            ul{
                padding: 0;
                list-style: circle;

                li{
                    width: 100%;
                    margin: 0 0 12px 0 !important;
                    padding-left: 20px;
                    position: relative;

                    &::before{
                        background-color: transparent;
                        border: 1px solid #EF7D05;
                        border-radius: 100%;
                        content: "";
                        height: 8px;
                        left: 0;
                        position: absolute;
                        top: 2px;
                        width: 8px;
                    }
                }

            }
        }        
    }
}