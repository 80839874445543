@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/components/forms/buttons';

@import '../../../styles/tools/mixins';

.features-arrow {
  @extend %btn-clear;
  bottom: 0;
  background-color: $neutra;
  height: 50px;
  padding: 0;
  position: absolute;
  width: 130px;
  z-index: 2;

  .icon {
    @include rem('font-size', 20px);
    color: $secondary-color;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  &.slick-prev {
    left: 0;
  }

  &.slick-next {
    right: 0;
  }

  &:hover {
    background-color: $secondary-dark;

    .icon {
      color: $neutra;
    }
  }
}