@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/components/typography';

@import '../../../styles/tools/mixins';

.card-info {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  flex-flow: row wrap;
  height: 100%;
  padding: 20px 80px;
  position: relative;
  text-align: left;
  width: 44.5%;
  vertical-align: top;

  .description,
  .link-item,
  .title-main {
    position: relative;
    width: 100%;
    vertical-align: top;
  }

  .description {
    @include font($font-default, 16px, 400, $neutra-04);
    line-height: 1.4em;

    > p {
      margin: 0 0 20px;

      a {
        font-weight: 700;
        color: #EF7D05;
      }
    }

  }

  .link-item {
    cursor: pointer;
    float: left;
    
    .icon {
      @include rem('font-size', 14px);
      color: $secondary-color;
      float: left;
    }
  }

  .title-main {
    @extend %title-super;
    color: $neutra-04;
    line-height: 1.1em;
    margin: 0 0 20px;
    text-transform: uppercase;
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .card-info {
    max-width: 500px;
    padding: 70px 0 0;
    width: 100%;
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .card-info {
    padding: 70px 20px 0;
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .card-info {
    padding: 30px 20px 0;
  }
}