@import '../../../../../styles/settings/colors';

.ships-item-relationships-content {
  float: left;
  position: relative;
  width: 100%;
  vertical-align: top;

  &--background {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .title-text {
    margin: 60px 0 40px;

    .text {
      color: $neutra;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 768px) {
  .ships-item-relationships-content {    
    .title-text {
      margin-bottom: 90px;
    }
  }
}
