@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.block-ship-info {
  @include parallax-fix;
  float: left;
  position: relative;
  padding: 20px 65px;
  text-align: left;
  width: calc(100% - 640px);
  vertical-align: top;

  .description,
  .link-item,
  .title {
    float: left;
    line-height: 1.4em;
    position: relative;
    width: 100%;
    vertical-align: top;
  }

  .description {
    @include font($font-default, 16px, 300, $primary-dark);
    > p {
      margin-top: 0;
    }
  }

  .link-item {
    margin: 90px 0;
    text-decoration: none;

    .icon,
    .text {
      float: left;
      margin: 0;
      position: relative;
      width: auto;
      vertical-align: top;
    }

    .icon {
      @include rem('font-size', 13px);
      color: $secondary-color;
      margin: 0 0 0 20px;
      top: 4px;
    }

    .text {
      @include font($font-default, 14px, 800, $secondary-color);
      text-transform: uppercase;
    }
  }

  .title {
    @include font($font-default, 40px, 700, $primary-dark);
    margin-top: 0;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .block-ship-info {
    padding: 0 0 0 50px;
    width: calc(100% - 255px);

    .link-item {
      margin-bottom: 0;
      margin-top: 20px;
    }

    .title {
      @include rem('font-size', 24px);
      font-weight: 600;
    }
  }
}

/* Custom, iPhone Retina */ 
@media only screen and (max-width : 480px) {
  .block-ship-info {
    padding: 0;
    width: 100%;
  }
}
