@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/components/animations';

@import '../../../styles/tools/mixins';

.ships-card {
  background-color: transparent;
  cursor: pointer;
  float: left;
  padding: 10px 0;
  position: relative;
  text-decoration: none;
  width: 100%;
  vertical-align: top;

  &--content,
  &--info,
  &--image {
    float: left;
    position: relative;
    width: 100%;
    vertical-align: top;
  }

  &--content {
    background-color: transparent;
    box-shadow: 0px 4px 40px rgba(25, 38, 57, 0.24);
  }

  &--image {
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    width: 100%;

    .image {
      float: left;
      width: 100%;
      vertical-align: top;
    }
  }

  &--info {
    align-items: center;
    background-color: $neutra;
    display: inline-flex;
    justify-content: space-between;
    padding: 25px 15px;

    &--value {
      float: left;
      position: relative;
    }
  }

  &--link {
    float: right;
  }

  .title {
    @include font($font-default, 32px, 800, $neutra);
    float: left;
    line-height: 1.3em;
    margin: 0 0 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    text-align: left;
    width: 100%;
    vertical-align: top;
    white-space: nowrap;
    z-index: 11;
  }

  .ships-card-modal {
    opacity: 0;
    pointer-events: none;
    top: 65px;
    transform: translate(0, 0);
    width: 100%;
    z-index: 10;

    &-list {
      &--item {
        display: none;

        &:nth-child(1),
        &:nth-child(2) {
          display: inline-block;
        }
      }
    }
  }

  &:hover {
    z-index: 999;

    .ships-card-modal {
      display: inline-block;
      opacity: 1;
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .ships-card {
    .ships-card-modal {
      filter: none;
      display: inline-block;
      opacity: 1;
      pointer-events: auto;
      position: relative;
      top: 0;

      .ships-card-modal-list {
        &:nth-child(3) {
          display: none;
        }
      }
    }
  }
}